import {
  Card,
  Avatar,
  CardHeader,
  CardActionArea,
  CardProps,
} from "@mui/material";

export interface HoleCardProps {
  onClick?: (
    event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>
  ) => void;
  name?: string;
  si: number;
  par: number;
}

const HoleCard = ({
  par,
  name,
  si,
  onClick,
  ...cardProps
}: HoleCardProps & Omit<CardProps, "onClick">) => (
  <Card {...cardProps}>
    <CardActionArea onClick={onClick}>
      <CardHeader
        avatar={
          <Avatar aria-label="par" sx={{ bgcolor: "primary.main" }}>
            {par}
          </Avatar>
        }
        title={name}
        subheader={`SI ${si}`}
        sx={{ p: 1 }}
      />
    </CardActionArea>
  </Card>
);

export default HoleCard;
