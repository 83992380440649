import { Selector, SelectorProps } from "@debaanop/ui-lib";
import { Score, ScoreProps } from "../ScoreCard";
import { IconButton } from "@mui/material";

export type StrokeInputProps = {
  options: Record<string, ScoreProps>;
} & Omit<SelectorProps, "options">;

const CustomOption =
  ({
    options,
    setMenuOpen,
  }: Pick<StrokeInputProps, "options" | "setMenuOpen">) =>
  ({ key, value }: { key: string; value: string }) => {
    return (
      <IconButton
        onClick={() => setMenuOpen(true)}
        sx={{ width: 48, height: 48, position: "relative" }}
      >
        <Score {...options[key]} />
      </IconButton>
    );
  };

const StrokeInput = ({ options, setMenuOpen, ...props }: StrokeInputProps) => (
  <Selector
    {...props}
    renderOption={CustomOption({ options, setMenuOpen })}
    setMenuOpen={setMenuOpen}
    options={Object.keys(options).reduce(
      (acc, option) => ({ ...acc, [option]: option }),
      {} as Record<string, string>
    )}
    numeric={true}
    orientation="horizontal"
  />
);
export default StrokeInput;
