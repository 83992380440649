import { useFlightApi } from "../services/flight/FlightProvider";
import { useQuery } from "@tanstack/react-query";

export const COURSE_FLIGHT = "courseFlight";

export const useCourseFlight = (course: string) => {
  const { courseFlight } = useFlightApi();
  return useQuery({
    queryKey: [COURSE_FLIGHT, { course }],
    queryFn: () => courseFlight(course),
  });
};
