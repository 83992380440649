import { useMemo } from "react";

import { Player, PlayerHole } from "../../types";
import useSlicedData from "./useSlicedData";
import { useAllCourses } from "../../queries";

const useHoleData = (
  course = "Ranst"
): Record<string, Record<string, PlayerHole[]>> => {
  const playerData = useSlicedData(course);

  const { data: courses } = useAllCourses();

  const players = {
    pieter: [],
    chris: [],
    tom: [],
  } as Record<Player, PlayerHole[]>;

  const holes = (courses?.find((c) => c.id === course)?.holes ?? []).reduce(
    (acc, _, i) => ({ ...acc, [i.toString()]: players }),
    {} as Record<string, Record<string, PlayerHole[]>>
  );

  const d = useMemo(
    () =>
      playerData?.reduce(
        (acc, p) => ({
          ...acc,
          ...p.holes.reduce(
            (acc2, h, index) => ({
              ...acc2,
              [index.toString()]: {
                ...acc[index.toString()],
                [p.player]: [...acc[index.toString()][p.player], h],
              },
            }),
            holes
          ),
        }),
        holes
      ) ?? holes,
    [playerData]
  );

  return Object.keys(d).reduce(
    (acc, hole) => ({
      ...acc,
      [hole]: Object.keys(d[hole]).reduce(
        (acc2, player) => ({
          ...acc2,
          [player]: d[hole][player]
            // .sort((a, b) => a.date.localeCompare(b.date))
            .slice(-5)
            .reverse(),
        }),
        {}
      ),
    }),
    holes
  );
};

export default useHoleData;
