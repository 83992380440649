import { ActivePanel, useView } from "@debaanop/ui-lib";
import type { ContextInterface } from "@debaanop/ui-lib";

import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const useViewWithRouting = (): [
  ActivePanel[],
  {
    openPanel: (panel: string, props: ContextInterface) => void;
    closePanel: (panel: string) => void;
  }
] => {
  const [
    openPanels,
    {
      api: { openPanel, closePanel },
      setOpenPanels,
    },
  ] = useView();
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const panels = JSON.parse(searchParams.get("detail") ?? "[]");
    // openPanel(panel, context);
    setOpenPanels(Array.isArray(panels) ? panels : [panels]);
  }, [searchParams]);

  const setPanels = (panels: ActivePanel[] = []) => {
    if (panels && panels.length > 0) {
      searchParams.set("detail", JSON.stringify(panels));
    } else {
      searchParams.delete("detail");
    }
    setSearchParams(searchParams);
  };

  return [
    openPanels,
    {
      openPanel: (panel: string, props: ContextInterface) => {
        setPanels(openPanel(panel, props));
      },
      closePanel: (panel: string) => {
        setPanels(closePanel(panel));
      },
    },
  ];
};

export const usePanel = () => {
  const [_, { openPanel }] = useViewWithRouting();
  return openPanel;
};

export function useActivePanel<T = {}>(
  activeKey: string
): T & (ActivePanel | undefined) {
  const [panels] = useViewWithRouting();
  return panels.find(({ key }) => key === activeKey) as T &
    (ActivePanel | undefined);
}

export default useViewWithRouting;
