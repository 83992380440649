import { Avatar, AvatarProps, Box } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import { PlayerHole } from "../../types";
import { holeVariant } from "../../helpers";

export const holeMapper = (hole: PlayerHole, index?: number): ScoreProps => ({
  key: index,
  children: hole.strokes?.toString() ?? "",
  variant: hole.strokes ? holeVariant(hole.strokes, hole.par) : "undefined",
});

export type ScoreVariant =
  | "undefined"
  | "birdie"
  | "par"
  | "bogey"
  | "doubleBogey"
  | "tripleBogey"
  | "worse"
  | "complete"
  | "unstarted"
  | "incomplete";

export type ScoreProps = {
  children?: string | number | ReactNode;
  variant?: ScoreVariant;
  borderWidth?: CSSProperties["borderWidth"];
} & Omit<AvatarProps, "variant" | "children">;

const variantStyle: Record<
  ScoreVariant,
  { borderStyle: CSSProperties["borderStyle"]; variant: AvatarProps["variant"] }
> = {
  undefined: {
    borderStyle: "none",
    variant: "square",
  },
  birdie: {
    borderStyle: "double",
    variant: "circular",
  },
  par: {
    borderStyle: "solid",
    variant: "circular",
  },
  bogey: {
    borderStyle: "none",
    variant: "rounded",
  },
  doubleBogey: {
    borderStyle: "solid",
    variant: "square",
  },
  tripleBogey: {
    borderStyle: "double",
    variant: "square",
  },
  worse: {
    borderStyle: "double",
    variant: "square",
  },
  complete: {
    borderStyle: "none",
    variant: "square",
  },
  incomplete: {
    borderStyle: "none",
    variant: "square",
  },
  unstarted: {
    borderStyle: "solid",
    variant: "square",
  },
};

const customColors = {
  complete: {
    backgroundColor: "#55B4A4",
  },
  incomplete: {
    backgroundColor: "#F8BCA0",
  },
  unstarted: {
    backgroundColor: "#eee",
    borderColor: "#ccc",
  },
};

type customColorKeys = keyof typeof customColors;

const customStyle = (
  variant: ScoreVariant,
  borderWidth: CSSProperties["borderWidth"] = "5px"
) => ({
  borderColor: "#999",
  borderWidth: ["par", "bogey", "doubleBogey", "unstarted"].includes(variant)
    ? "1px"
    : borderWidth,
  color: "inherit",
  ...(variant === "undefined"
    ? {
        border: "none",
        borderBottom: "2px solid #ccc",
      }
    : {}),
  ...(customColors[variant as customColorKeys] ?? { backgroundColor: "white" }),
});

const Score = (props: ScoreProps) => {
  const { variant, borderWidth, ...other } = {
    variant: "bogey" as ScoreVariant,
    ...props,
  };
  const { borderStyle, variant: avatarVariant } = variantStyle[variant];
  return (
    <Box
      sx={{
        minWidth: 16,
        minHeight: 16,
        maxWidth: 38,
        maxHeight: 38,
        flex: "1 0 0",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          width: "100%",
          paddingTop: "100%",
          position: "relative",
          containerType: "inline-size",
        }}
      >
        <Avatar
          {...other}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            fontWeight: 500,
            borderStyle,
            ...customStyle(variant, borderWidth),
            fontSize: "50cqw",
            lineHeight: "1em",
            ...other.sx,
          }}
          variant={avatarVariant}
        />
      </Box>
    </Box>
  );
};

export default Score;
