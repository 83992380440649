import { createContext, FC, ReactNode, useContext } from "react";
import type { FlightData, Player } from "../../types";

import {
  FlightProvider as IndexedDBProvider,
  useApi as useIndexedDBApi,
} from "./indexedDB/FlightProvider";

export interface Api {
  courseFlight: (courseId: string) => Promise<FlightData[]>;
  createFlight: (
    courseId: string,
    handicaps: Record<Player, number>
  ) => Promise<FlightData[]>;
  updateFlight: (
    courseId: string,
    handicaps: Record<Player, number>
  ) => Promise<FlightData[]>;
  updateHoleStrokes: (
    courseId: string,
    holeIndex: number,
    strokes: Record<Player, number>
  ) => Promise<FlightData[]>;
}

export const FlightContext = createContext<Api | null>(null);

export type FlightProviderProps = {
  children: ReactNode;
  backend: "indexedDB";
};

export const FlightProvider: FC<FlightProviderProps> = ({
  children,
  backend,
}) => {
  return (
    <IndexedDBProvider>
      <IndexedDBWrapper>{children}</IndexedDBWrapper>
    </IndexedDBProvider>
  );
};

const IndexedDBWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const api = useIndexedDBApi();

  return (
    <FlightContext.Provider value={api}>{children}</FlightContext.Provider>
  );
};

export const useFlightApi = (): Api => {
  const api = useContext(FlightContext);
  if (!api) {
    throw Error("not used within proper provider");
  }

  return api;
};
