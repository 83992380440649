import { createContext, FC, ReactNode, useContext } from "react";
import type { UserProfile, Token } from "../../types";

import {
  GoogleAuthProvider,
  useAuth as useGoogleAuth,
  GoogleAuthConfig,
} from "./GoogleAuth/GoogleAuthProvider";

import {
  MockAuthProvider,
  useAuth as useMockAuth,
} from "./mock/MockAuthProvider";

export interface AuthApi {
  isLoggedin: boolean;
  isLoggingin: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getUser: () => Promise<UserProfile | undefined>;
  token: string | undefined;
}

export const AuthContext = createContext<AuthApi | null>(null);

export type AuthProviderProps = {
  children: ReactNode;
  backend: "mock" | "google";
  config: {
    google: GoogleAuthConfig;
  };
};

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
  backend,
  config,
}) => {
  if (backend === "google") {
    return (
      <GoogleAuthProvider config={config.google}>
        <GoogleAuthWrapper>{children}</GoogleAuthWrapper>
      </GoogleAuthProvider>
    );
  }
  return (
    <MockAuthProvider>
      <MockWrapper>{children}</MockWrapper>
    </MockAuthProvider>
  );
};

const GoogleAuthWrapper: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const auth = useGoogleAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const MockWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const auth = useMockAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthApi => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw Error("not used within proper provider");
  }

  return auth;
};
