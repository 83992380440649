import { FC } from "react";
import {
  Typography,
  Avatar,
  TextField,
  Stack,
  Box,
  Checkbox,
} from "@mui/material";
import {
  SelectableOption,
  SelectableOptionProps,
  IntegerInput,
} from "@debaanop/ui-lib";
import { CheckBox, CheckBoxOutlineBlank, Checklist } from "@mui/icons-material";

export type SelectableFlightPlayerProps = {
  avatarSrc?: string;
  name: string;
  courseHandicap?: string;
  onChange: (hcp: string) => void;
} & Omit<SelectableOptionProps, "onChange">;

export const SelectableFlightPlayer: FC<SelectableFlightPlayerProps> = ({
  avatarSrc,
  name,
  courseHandicap,
  onChange,
  selected,
  disabled,
  ...props
}) => {
  return (
    <SelectableOption
      {...props}
      selected={selected}
      disabled={disabled}
      sx={{ pr: 1, minWidth: 300 }}
    >
      <Stack
        sx={{ width: "100%" }}
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{ width: 48, textAlign: "left" }}>
          {selected ? (
            <CheckBox color="success" />
          ) : (
            <CheckBoxOutlineBlank color="disabled" />
          )}
        </Box>
        <Avatar src={avatarSrc} />
        <Typography
          sx={{ flex: "1 1 auto", pl: 2, fontWeight: selected ? 800 : 500 }}
          variant="button"
        >
          {name}
        </Typography>
        <IntegerInput
          sx={{ flex: "0 0 48px" }}
          label="Hcp"
          variant="standard"
          size="small"
          disabled={disabled || !selected}
          value={courseHandicap}
          inputProps={{
            step: 1,
            min: 0,
            max: 54,
          }}
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
          }}
          onChange={onChange}
        />
      </Stack>
    </SelectableOption>
  );
};

SelectableFlightPlayer.displayName = "SelectableFlightPlayer";

export default SelectableFlightPlayer;
