import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { usePanel } from "../../hooks";

import CoursePage from "./CoursePage";
import { players as allPlayers } from "../../helpers";

import { scoreCardMapper } from "../../components/ScoreCard";

import useCourseDistribution from "../../services/distribution/useCourseDistribution";
import usePlayerData from "../../services/distribution/usePlayerData";
import { Player } from "../../types";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useApp } from "../../providers/appContext";
import { useCourseFlight } from "../../queries/flights";
import { useFlightDetails } from "../../components/FlightDetails";
import { useFlightUpdate } from "../../mutations/flights";
import { useState } from "react";
import { useAllCourses } from "../../queries";

const Course = () => {
  const { course } = useParams();
  const navigate = useNavigate();
  const [flightDialogOpen, setFlightDialogOpen] = useState<boolean>(false);
  const { state, dispatch } = useApp();
  const [player, setPlayer] = useLocalStorage<Player>(
    "currentPlayer",
    "pieter"
  );

  const { data: courses } = useAllCourses();
  const courseData = courses?.find((c) => c.name === course);

  const { data: flights } = useCourseFlight(course!);
  const { mutate: updateFlight } = useFlightUpdate(course!);
  const { reset, isDirty, ...p } = useFlightDetails({
    players: allPlayers.map((p) => ({
      key: p,
      name: p,
      avatarSrc: `/covers/${p}.jpg`,
      hcp: flights?.find((f) => f.player === p)?.courseHandicap,
      selected: !!flights?.find((f) => f.player === p),
    })),
  });

  const openPanel = usePanel();
  const playerDistributionData = useCourseDistribution(course!);
  const players = playerDistributionData.map(({ label }) => label);
  const playerData = usePlayerData(course as string);
  const scoreCard =
    playerData?.[player]?.[0] && scoreCardMapper(playerData?.[player]?.[0]);

  return (
    <>
      <CoursePage
        title={course!}
        playerDistributionData={playerDistributionData}
        onClickPlayerIndex={(id) =>
          openPanel("player", { id: players[2 - id], course })
        }
        onOpenPlayer={(id) => openPanel("player", { id, course })}
        onGoBack={() => {
          navigate("../");
        }}
        scoreCard={scoreCard}
        player={player}
        setPlayer={(id) => {
          setPlayer(id);
        }}
        slidingWindowProps={{ ...state, ...dispatch }}
        flightCardProps={
          flights?.length ?? -1 > 0
            ? {
                _id: flights![0].id,
                players: flights!.map((f) => ({
                  key: f.player,
                  avatarSrc: `/covers/${f.player}.jpg`,
                })),
                onClick: () => {
                  openPanel("flight", { course });
                },
                nbHoles: courseData?.holes.length as 9 | 18,
                completedHoles: Object.keys(flights![0].strokes).map((i) =>
                  parseInt(i)
                ),
              }
            : undefined
        }
        flightDetailsProps={{
          onSubmit: (flightPlayers) => {
            updateFlight(flightPlayers, {
              onSuccess: () => {
                reset();
                openPanel("flight", { course });
                setFlightDialogOpen(false);
              },
            });
          },
          ...p,
        }}
        flightDialogOpen={flightDialogOpen}
        setFlightDialogOpen={setFlightDialogOpen}
      />
    </>
  );
};

export default Course;
