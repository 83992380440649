import { useState } from "react";
import type { Token, UserProfile } from "../../../types";

import type { AuthApi } from "../AuthProvider";
import { useGoogleLogin } from "@react-oauth/google";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

export const useAuth = (): AuthApi => {
  const [token, setToken] = useLocalStorage<string | undefined>(
    "access_token",
    undefined
  );

  const [loading, setLoading] = useState<boolean>(false);
  const login = useGoogleLogin({
    scope:
      // "email profile https://www.googleapis.com/auth/spreadsheets.currentonly https://www.googleapis.com/auth/script.projects",
      "email profile https://www.googleapis.com/auth/script.scriptapp",
    onSuccess: (tokenResponse) => {
      setToken(tokenResponse.access_token);
      setLoading(false);
    },
  });
  const api = {
    isReady: true,
    isLoggedin: !!token,
    isLoggingin: loading,
    token,
    login: async () => {
      setLoading(true);
      await login();
    },
    logout: async () => {
      setToken(undefined);
    },
    getUser: async () => {
      if (api.isLoggedin) {
        return {
          name: "Pieter",
          email: "pietersoudan@gmail.com",
          picture: "/public/covers/pieter.jpg",
        } as UserProfile;
      } else return undefined;
    },
  };

  return {
    ...api,
  };
};
