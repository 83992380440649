import { useMemo } from "react";

import { Player, Distribution } from "../../types/course";
import { addD, emptyD } from "./helpers";
import useSlicedData from "./useSlicedData";

type PlayerDistribution = Distribution & { label: Player };

// group all results by player
const useCourseDistribution = (course: string): PlayerDistribution[] => {
  const playerData = useSlicedData(course);

  const e = {
    pieter: { ...emptyD, label: "pieter" as Player },
    chris: { ...emptyD, label: "chris" as Player },
    tom: { ...emptyD, label: "tom" as Player },
  };

  const d = useMemo(
    () =>
      playerData?.reduce(
        (acc, playerData) => ({
          ...acc,
          [playerData.player]: {
            ...acc[playerData.player],
            ...addD(playerData.distribution, acc[playerData.player]),
          },
        }),
        e
      ) ?? e,
    [playerData]
  );

  return Object.values(d);
};

export default useCourseDistribution;
