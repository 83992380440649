import { AppBar, Box, Paper } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { CoverView, Selector, SelectorProps } from "@debaanop/ui-lib";
import {
  StrokesDistribution,
  StrokesDistributionProps,
} from "../../components/StrokesDistribution";
import { ScoreCard, ScoreCardProps } from "../../components/ScoreCard";

type CoursePlayerProps = {
  title?: string;
  selectorProps: SelectorProps;
  holeDistributionData: Record<string, StrokesDistributionProps["data"]>;
  parDistributionData?: Record<string, StrokesDistributionProps["data"]>;
  onClickHoleIndex: (id: number) => void;
  scoreCards?: Record<string, ScoreCardProps[]>;
};

const CoursePlayerSheet = ({
  title,
  selectorProps,
  holeDistributionData,
  parDistributionData,
  onClickHoleIndex,
  scoreCards,
}: CoursePlayerProps) => {
  const { value } = selectorProps;
  const nbHoles = Object.keys(holeDistributionData).length;

  return (
    <CoverView
      cover={"/covers/group.jpg"}
      scrollable
      title={title}
      stickyContent={
        <AppBar>
          <Selector {...selectorProps} />
        </AppBar>
      }
    >
      <Paper sx={{ m: 2, flex: "1 0 0", height: nbHoles > 9 ? 600 : 400 }}>
        <StrokesDistribution
          layout="horizontal"
          data={holeDistributionData[value]}
          onClick={({ index }) =>
            onClickHoleIndex(holeDistributionData[value].length - index - 1)
          }
        />
      </Paper>
      {parDistributionData && (
        <Paper sx={{ m: 2, mt: 0, flex: "1 0 0", height: 280 }}>
          <StrokesDistribution
            layout="vertical"
            padding={0.5}
            data={parDistributionData[value]}
          />
        </Paper>
      )}
      <Box
        sx={{
          position: "relative",
          m: 2,
          mb: 16,
        }}
      >
        <AnimatePresence>
          {Object.keys(scoreCards ?? {}).map(
            (scoreCardIndex) =>
              scoreCardIndex === value && (
                <motion.div
                  transition={{ ease: "easeOut", duration: 0.3 }}
                  key={value}
                  initial={{ translateX: "-120%" }}
                  animate={{ translateX: 0 }}
                  exit={{ translateX: "120%" }}
                >
                  <Box sx={{ position: "absolute", width: "100%" }}>
                    {scoreCards?.[scoreCardIndex].map((scoreCard) => (
                      <ScoreCard {...scoreCard} sx={{ mb: 2 }} />
                    ))}
                  </Box>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </Box>
    </CoverView>
  );
};

export default CoursePlayerSheet;
