import { EmptyState } from "@debaanop/ui-lib";

import { Google, Login as LoginIcon } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useAuth } from "../../services/auth/AuthProvider";

const Login = ({}: {}) => {
  const { isLoggedin, isLoggingin, login } = useAuth();
  return (
    <EmptyState
      icon={<Google />}
      fullHeight
      label="Please login using your Google Account"
      size="large"
    >
      {isLoggingin ? (
        <CircularProgress variant="indeterminate" />
      ) : (
        <Button
          variant="contained"
          startIcon={<LoginIcon />}
          onClick={() => login()}
        >
          Login
        </Button>
      )}
    </EmptyState>
  );
};

export default Login;
