import { Card, CardMedia, CardHeader, CardActionArea } from "@mui/material";

export interface CourseCardProps {
  _id: string;
  onClick?: (
    event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>
  ) => void;
  coverUrl?: string;
  name: string;
  description?: string;
}

const CourseCard = ({
  coverUrl,
  name,
  description,
  onClick,
}: CourseCardProps) => (
  <Card>
    <CardActionArea onClick={onClick}>
      <CardMedia image={coverUrl} title={name} style={{ height: 180 }} />
      <CardHeader title={name} subheader={description} />
    </CardActionArea>
  </Card>
);

export default CourseCard;
