import { createContext, FC, ReactNode } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import type { AuthApi } from "../AuthProvider";
import { useAuth } from "./api";

export { useAuth } from "./api";

export const GoogleAuthContext = createContext<AuthApi | null>(null);

export interface GoogleAuthConfig {
  clientId: string;
}

export interface GoogleAuthProviderProps {
  children: ReactNode;
  config: GoogleAuthConfig;
}

const Wrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const api = useAuth();
  return (
    <GoogleAuthContext.Provider value={api}>
      {children}
    </GoogleAuthContext.Provider>
  );
};

export const GoogleAuthProvider: FC<GoogleAuthProviderProps> = ({
  children,
  config,
}) => {
  return (
    <GoogleOAuthProvider clientId={config.clientId}>
      <Wrapper>{children}</Wrapper>
    </GoogleOAuthProvider>
  );
};
