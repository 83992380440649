import { FC, useState } from "react";
import { Button, Stack } from "@mui/material";
import SelectableFlightPlayer, {
  SelectableFlightPlayerProps,
} from "../SelectableFlightPlayer/SelectableFlightPlayer";
import { AlignRight } from "@debaanop/ui-lib";

export type FlightDetailsProps = {
  onSubmit: (flightPlayers: Record<string, number>) => void;
  flightPlayers: SelectableFlightPlayerProps[];
};

export const useFlightDetails: (props: {
  players: {
    key: string;
    name: string;
    avatarSrc?: string;
    hcp?: number;
    selected?: boolean;
  }[];
}) => Omit<FlightDetailsProps, "onSubmit"> & {
  reset: () => void;
  isDirty: boolean;
} = ({ players }) => {
  const [flightPlayers, setFlightPlayers] = useState<
    Record<string, { selected: boolean; hcp: number }>
  >({});
  return {
    flightPlayers: players.map((p) => ({
      ...p,
      courseHandicap:
        flightPlayers[p.key]?.hcp?.toString() ?? p.hcp?.toString() ?? "0",
      onClick: () => {
        setFlightPlayers((prev) => ({
          ...prev,
          [p.key]: {
            selected: !(prev[p.key]?.selected ?? p.selected ?? false),
            hcp: prev[p.key]?.hcp ?? p.hcp ?? 0,
          },
        }));
      },
      selected: !!(flightPlayers[p.key]?.selected ?? p.selected ?? false),
      onChange: (hcp: string) => {
        setFlightPlayers((prev) => ({
          ...prev,
          [p.key]: {
            ...prev[p.key],
            hcp: parseFloat(hcp),
          },
        }));
      },
    })),
    reset: () => {
      setFlightPlayers({});
    },
    isDirty: Object.keys(flightPlayers).length > 0,
  };
};

export const FlightDetails: FC<FlightDetailsProps> = ({
  flightPlayers,
  onSubmit,
}) => {
  return (
    <Stack sx={{ height: "100%" }} justifyContent={"space-between"}>
      <Stack gap={2} sx={{ mb: 2 }}>
        {flightPlayers.map((p) => (
          <SelectableFlightPlayer {...p} />
        ))}
      </Stack>
      <AlignRight>
        <Button
          variant="contained"
          color="primary"
          disabled={flightPlayers.filter((p) => p.selected).length === 0}
          onClick={() => {
            onSubmit(
              flightPlayers
                .filter((p) => p.selected)
                .map((p) => ({
                  id: p.key,
                  hcp: parseFloat(p.courseHandicap ?? "0"),
                }))
                .reduce(
                  (acc, { id, hcp }) => ({
                    ...acc,
                    [id]: hcp,
                  }),
                  {} as Record<string, number>
                )
            );
          }}
        >
          Create
        </Button>
      </AlignRight>
    </Stack>
  );
};

FlightDetails.displayName = "FlightDetails";

export default FlightDetails;
