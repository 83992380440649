import { Paper, Typography, Stack, Chip } from "@mui/material";
import { ResponsiveBar, BarSvgProps } from "@nivo/bar";

export type DistributionData = {
  birdie: number;
  par: number;
  bogey: number;
  doubleBogey: number;
  worse: number;
  label?: string;
};

const axisH = {
  tickSize: 0,
  tickPadding: 12,
};

const axisV = {
  tickSize: 0,
  tickPadding: 12,
};

const divergingCommonProps = (
  layout: BarSvgProps<DistributionData>["layout"],
  noLabels?: boolean,
  noPadding?: boolean
): Partial<BarSvgProps<DistributionData>> => {
  const padding = noPadding ? 0 : 10;
  const labelPadding = noLabels ? 0 : layout === "vertical" ? 30 : 70;
  const extraLabelPadding = noLabels ? 0 : 20;
  return {
    margin:
      layout === "vertical"
        ? {
            top: padding + labelPadding,
            right: padding,
            bottom: padding + labelPadding,
            left: padding,
          }
        : {
            top: padding + extraLabelPadding,
            right: padding,
            bottom: padding,
            left: padding + labelPadding,
          },
    keys: ["bogey", "par", "birdie", "doubleBogey", "worse"],
    padding: noPadding ? 0 : 0.2,
    labelTextColor: "inherit:lighten(1.4)",
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    indexBy: "label",
    minValue: -100,
    maxValue: 100,
    enableGridX: false,
    enableGridY: false,
    // reverse: layout === "vertical" ? true : false,
    axisTop: layout === "vertical" ? axisH : null,
    axisBottom: layout === "vertical" ? axisH : null,
    axisLeft: layout === "horizontal" ? axisV : null,
    axisRight: layout === "horizontal" ? axisV : null,
    valueFormat: (value: number) => `${Math.abs(value)}`,
    markers: [
      {
        axis: layout === "horizontal" ? "x" : "y",
        value: 0,
        lineStyle: { strokeOpacity: 0 },
        textStyle: { fill: "#2ebca6" },
        ...(noLabels
          ? {}
          : {
              legend: "Bogey or better",
              legendPosition:
                layout === "horizontal" ? "top-right" : "top-left",
              legendOrientation: layout,
              legendOffsetY: layout === "horizontal" ? -10 : 10,
            }),
      },
      {
        axis: layout === "horizontal" ? "x" : "y",
        value: 0,
        lineStyle: { stroke: "#f47560", strokeWidth: 1 },
        textStyle: { fill: "#e25c3b" },
        ...(noLabels
          ? {}
          : {
              legend: "doubleBogey or worse",
              legendPosition:
                layout === "horizontal" ? "top-left" : "bottom-left",
              legendOrientation: layout,
              legendOffsetY: layout === "horizontal" ? -10 : 10,
            }),
      },
    ],
  };
};

export type StrokesDistributionProps = {
  data: BarSvgProps<DistributionData>["data"];
  noLabels?: boolean;
  noPadding?: boolean;
  exactValues?: boolean;
} & Partial<BarSvgProps<DistributionData>>;

const StrokesDistribution = (props: StrokesDistributionProps) => {
  const { data, noLabels, noPadding, exactValues, ...other } = props;
  const d = data
    .map((data) => ({
      ...data,
      total:
        data.birdie + data.bogey + data.doubleBogey + data.par + data.worse,
    }))
    .filter(({ total }) => total > 0)
    .map(({ total, ...data }) => {
      const r = (d: number): number =>
        exactValues ? d : Math.round((100 * d) / total);
      return {
        ...data,
        birdie: r(data.birdie),
        par: r(data.par),
        bogey: r(data.bogey),
        doubleBogey: -r(data.doubleBogey),
        worse: -r(data.worse),
      };
    });
  const { min, max } = d.reduce(
    (memo, { doubleBogey, worse, bogey, par, birdie }) => {
      const min = doubleBogey + worse;
      const max = bogey + par + birdie;
      return {
        min: Math.min(memo.min, min),
        max: Math.max(memo.max, max),
      };
    },
    { min: 1000, max: -1000 }
  );

  return (
    <ResponsiveBar
      {...divergingCommonProps(props.layout ?? "vertical", noLabels, noPadding)}
      {...other}
      // padding={0.4}
      colors={["#97e3d5", "#61cdbb", "#55B4A4", "#F8BCA0", "#f47560"]}
      valueFormat={(v) =>
        exactValues ? Math.abs(v).toString() : `${Math.abs(v)}%`
      }
      data={
        (props.layout ?? "vertical") === "horizontal" ? d.slice().reverse() : d
      }
      minValue={min}
      maxValue={max}
      tooltip={(props) => {
        return (
          <Paper
            variant="elevation"
            sx={{
              p: 1,
              borderRadius: 2,
              boxShadow: "0px 4px 23px rgba(0, 0, 0, 0.09)",
            }}
          >
            <Stack direction={"row"} spacing={1} alignItems="center">
              <Typography
                variant="caption"
                key={props.id}
                sx={{ display: "block", flexGrow: 1 }}
              >
                {props.label}
              </Typography>
              <Chip
                size="small"
                sx={{
                  p: 0,
                  fontSize: "0.67em",
                  height: "1.02rem",
                  fontWeight: 800,
                }}
                label={props.value}
              />
            </Stack>
          </Paper>
        );
      }}
    />
  );
};

export default StrokesDistribution;
