import { Selector, useManagedSelector } from "@debaanop/ui-lib";
import { CheckBox } from "@mui/icons-material";
import {
  Box,
  Slider,
  Typography,
  Stack,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  StackProps,
} from "@mui/material";
import { range } from "lodash";
import { useState } from "react";

export type SlidingWindowProps = {
  on?: boolean;
  onToggle: (v: boolean) => void;
  best: number;
  size: number;
  percent: number;
  onChangeBest: (v: number) => void;
  onChangeSize: (v: number) => void;
  onChangePercent: (v: number) => void;
} & StackProps;

export const useSlidingWindow = () => {
  const [on, onToggle] = useState(true);
  const [best, onChangeBest] = useState(5);
  const [size, onChangeSize] = useState(10);
  const [percent, onChangePercent] = useState(100);
  return {
    best,
    size,
    percent,
    on,
    onToggle,
    onChangeBest,
    onChangeSize,
    onChangePercent,
  };
};

const SlidingWindow = ({
  on = true,
  onToggle,
  best,
  size,
  percent,
  onChangeBest,
  onChangeSize,
  onChangePercent,
  ...stackProps
}: SlidingWindowProps) => {
  const sizeOptions = range(5, 21).reduce(
    (acc, r) => ({
      ...acc,
      [r.toString()]: r.toString(),
    }),
    {}
  );
  const bestOptions = range(1, size + 1).reduce(
    (acc, r) => ({
      ...acc,
      [r.toString()]: r.toString(),
    }),
    {}
  );
  const sizeProps = useManagedSelector({
    options: sizeOptions,
    selected: size.toString(),
    onChange: (key) => {
      const s = parseInt(key);
      if (s < best) {
        onChangeBest(s);
      }
      onChangeSize(s);
    },
  });
  const bestProps = useManagedSelector({
    options: bestOptions,
    selected: best.toString(),
    onChange: (key) => onChangeBest(parseInt(key)),
  });
  return (
    <Stack sx={{ p: 2, ...stackProps.sx }} {...stackProps}>
      <FormGroup sx={{ mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={on}
              onChange={(_, checked) => onToggle(checked)}
            />
          }
          label="Slice the data"
        />
      </FormGroup>
      <ConfigItem label="size">
        <Selector {...sizeProps}></Selector>
      </ConfigItem>
      <ConfigItem label="best">
        <Selector {...bestProps}></Selector>
      </ConfigItem>
      <Box sx={{ p: 4, pt: 1, pb: 1, width: "100%" }}>
        <Slider
          value={percent}
          onChange={(_, v) => onChangePercent(v as number)}
        />
      </Box>
      {!on && (
        <Box
          sx={{
            position: "absolute",
            top: 60,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            pointerEvents: "auto",
          }}
        ></Box>
      )}
    </Stack>
  );
};

type ConfigItemProps = {
  label: string;
  children: React.ReactNode;
};

const ConfigItem = ({ label, children, ...props }: ConfigItemProps) => (
  <Paper
    sx={{
      pl: 2,
      pr: 2,
      mb: 2,
      backgroundColor: "background.default",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Typography>{label}:</Typography>
    <Box sx={{ flex: "1 0 0" }}>{children}</Box>
  </Paper>
);

export default SlidingWindow;
