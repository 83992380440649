import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { ScoreCard, ScoreCardProps } from "../../components/ScoreCard";
import BottomPanel from "../../components/BottomPanel/BottomPanel";
import HoleInput, {
  HoleInputProps,
} from "../../components/HoleInput/HoleInput";
import { useState } from "react";
import { Edit } from "@mui/icons-material";

export type FlightSheetProps = {
  title?: string;
  scoreCards: ScoreCardProps[];
  holeInputProps: HoleInputProps;
  submitScores: () => void;
  submitDisabled?: boolean;
};

const FlightSheet = ({
  title,
  scoreCards,
  holeInputProps,
  submitScores,
  submitDisabled = true,
}: FlightSheetProps) => {
  const [inputOpen, setInputOpen] = useState(true);
  return (
    <Stack
      direction={"column"}
      sx={{
        flex: "1 1 0",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <AppBar title={title} sx={{ background: "none" }}>
        <Toolbar>
          <IconButton></IconButton>
          <Typography variant="subtitle1">{title}</Typography>
        </Toolbar>
      </AppBar>
      <Stack
        sx={{
          flex: "1 1 0",
          position: "relative",
          justifyContent: "space-between",
          pb: 4,
        }}
      >
        <Box sx={{ p: 2 }}>
          {scoreCards.map((scoreCard) => (
            <ScoreCard {...scoreCard} sx={{ mb: 2 }} />
          ))}
        </Box>
        <Stack direction={"column"} alignItems={"center"} gap={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => setInputOpen(true)}
            startIcon={<Edit />}
          >
            Enter Scores
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={submitDisabled}
            onClick={() => submitScores()}
          >
            Submit!
          </Button>
        </Stack>
        <BottomPanel
          open={inputOpen}
          onClose={() => {
            setInputOpen(false);
          }}
          sx={{
            ml: 1,
            mr: 1,
          }}
        >
          <HoleInput {...holeInputProps} />
        </BottomPanel>
      </Stack>
    </Stack>
  );
};

export default FlightSheet;
