import { createContext, FC, ReactNode, useEffect } from "react";
import setupIndexedDB from "use-indexeddb";

import type { Api } from "../FlightProvider";
import { useApi } from "./api";

export { useApi } from "./api";

const idbConfig = {
  databaseName: "deBaanOpFlights",
  version: 2,
  stores: [
    {
      name: "flightdata",
      id: { keyPath: "id" },
      indices: [
        { name: "id", keyPath: "id", options: { unique: true } },
        { name: "course", keyPath: "courseId", options: { unique: false } },
        { name: "player", keyPath: "player", options: { unique: false } },
      ],
    },
  ],
};

export const FlightContext = createContext<Api | null>(null);

export interface FlightProviderProps {
  children: ReactNode;
}

export const FlightProvider: FC<FlightProviderProps> = ({ children }) => {
  // TODO: move this to do this only once for all dbs
  useEffect(() => {
    setupIndexedDB(idbConfig)
      .then(() => {
        // check if empty, if so, fill with mock data
      })
      .catch(() => {
        // TODO: what if indexedDB couldnt be initialized
      });
  }, []);

  const api = useApi();

  return (
    <FlightContext.Provider value={api}>{children}</FlightContext.Provider>
  );
};
