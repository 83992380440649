import { useQuery } from "@tanstack/react-query";
import { useApi } from "../services/api/DataProvider";

export const useAllCourses = () => {
  const { allCourses } = useApi();
  return useQuery({
    queryKey: ["allCourses"],
    queryFn: allCourses,
  });
};
