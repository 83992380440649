import {
  Card,
  Avatar,
  CardHeader,
  CardContent,
  Stack,
  CardProps,
  Box,
  StackProps,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatRelative, parseISO } from "date-fns";
import { nlBE } from "date-fns/locale";

import Score, { ScoreProps, holeMapper } from "./Score";
import { variantReducer, stableFordReducer } from "../../helpers";
import { emptyD } from "../../services/distribution/helpers";

import {
  StrokesDistribution,
  StrokesDistributionProps,
} from "../StrokesDistribution";
import { PlayerData } from "../../types";
import { QuestionMark } from "@mui/icons-material";
type Score = ScoreProps | undefined;
export type ScoreCardProps = {
  _id: string;
  title?: string;
  subTitle?: string;
  sf?: number;
  sf1?: number;
  sf2?: number;
  strokes?: number;
  strokesDistribution?: StrokesDistributionProps;
  scores: Score[];
  scores2?: Score[];
  gap?: StackProps["gap"];
} & CardProps;

const useStyles = makeStyles({
  action: {
    margin: 0,
  },
});

const undefinedMapper = (score: Score): ScoreProps => {
  if (score) {
    return score;
  }
  return {
    variant: "undefined",
    children: <QuestionMark fontSize="inherit" color="disabled" />,
  };
};

export const scoreCardMapper = ({
  player,
  sf,
  strokes,
  holes,
  date,
}: PlayerData) => ({
  subTitle: formatRelative(parseISO(date), new Date(), { locale: nlBE }),
  title: player as string,
  sf,
  strokes,
  _id: "s",
  scores: holes.slice(0, 9).map(holeMapper),
  ...(holes.length > 9
    ? {
        scores2: holes.slice(9, 18).map(holeMapper),
        sf1: holes.slice(0, 9).reduce(stableFordReducer, 0),
        sf2: holes.slice(9, 18).reduce(stableFordReducer, 0),
      }
    : {}),
  strokesDistribution: {
    data: [holes.reduce(variantReducer, emptyD)],
  },
});

type ScoreRowProps = {
  gap: StackProps["gap"];
  scores: Score[];
  sf?: number;
};

export const ScoreRow = ({ gap, scores, sf }: ScoreRowProps) => (
  <Stack
    direction="row"
    gap={gap}
    justifyContent="space-between"
    alignItems={"center"}
  >
    {scores.map(undefinedMapper).map((props, index) => (
      <Score
        {...props}
        borderWidth={gap === 1 ? "thick" : "3px"}
        key={`score-${index}`}
      />
    ))}
    {sf && (
      <Avatar
        variant="rounded"
        sx={{
          width: 30,
          height: 30,
          fontSize: "0.8em",
          fontWeight: 600,
        }}
      >
        {sf}
      </Avatar>
    )}
  </Stack>
);

const ScoreCard = ({
  title,
  scores,
  scores2,
  subTitle,
  strokes,
  sf,
  sf1,
  sf2,
  strokesDistribution,
  gap = 1,
  sx,
  ...other
}: ScoreCardProps) => {
  const classes = useStyles();
  return (
    <Card {...other} sx={{ maxWidth: "800px", ...sx }}>
      {title && (
        <CardHeader
          classes={{ action: classes.action }}
          title={title}
          subheader={subTitle}
          avatar={
            strokes ? (
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                  fontSize: "1.1em",
                  fontWeight: 800,
                }}
              >
                {strokes}
              </Avatar>
            ) : undefined
          }
          action={
            sf ? (
              <Avatar
                variant="rounded"
                sx={{
                  width: 40,
                  height: 30,
                  fontSize: "1em",
                  fontWeight: 800,
                  backgroundColor: "secondary.main",
                }}
              >
                {sf}
              </Avatar>
            ) : undefined
          }
        ></CardHeader>
      )}
      <CardContent sx={{ pt: 0 }}>
        <Stack gap={gap}>
          {strokesDistribution && (
            <Box sx={{ width: "100%", height: 20, mb: 1 }}>
              <StrokesDistribution
                {...strokesDistribution}
                layout="horizontal"
                noLabels
                noPadding
                exactValues
              />
            </Box>
          )}
          <ScoreRow gap={gap} scores={scores} sf={sf1} />
          {scores2 && <ScoreRow gap={gap} scores={scores2} sf={sf2} />}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ScoreCard;
