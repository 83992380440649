import { FC } from "react";
import { Box, IconButton, Paper, PaperProps, Slide } from "@mui/material";
import { Close } from "@mui/icons-material";

export type BottomPanelProps = {
  open: boolean;
  onClose: () => void;
} & PaperProps;

export const BottomPanel: FC<BottomPanelProps> = ({
  open,
  onClose,
  children,
  ...props
}) => {
  return (
    <Slide in={open} direction="up">
      <Paper
        {...props}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          p: 0,
          pb: 4,
          overflow: "visible",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          boxShadow: "0px 4px 23px rgba(0, 0, 0, 0.09)",
          ...props.sx,
        }}
      >
        {children}
        <IconButton
          sx={{
            position: "absolute",
            top: -8,
            right: 20,
            transform: "translateY(-100%)",
          }}
          onClick={() => {
            onClose();
          }}
        >
          <Close />
        </IconButton>
      </Paper>
    </Slide>
  );
};

export default BottomPanel;
