import { createContext, FC, ReactNode } from "react";

import type { Api } from "../DataProvider";
import { useApi as useApiInternal } from "./api";
import { useAuth } from "../../auth/AuthProvider";

export const DataContext = createContext<Api | null>(null);

export interface GSheetsConfig {
  // mainUrl: string;
  scriptId: string;
}

const defaultHeaders = {
  // "Content-Length": "0",
  // "Content-Type": "text/plain",
  "Content-Type": "application/json",
};

export interface DataProviderProps {
  children: ReactNode;
  config: GSheetsConfig;
}

export const useApi = ({ scriptId }: GSheetsConfig) => {
  const { token } = useAuth();
  const authHeaders = token ? { Authorization: `Bearer ${token}` } : undefined;
  return useApiInternal({
    // ?request=playerData&params={"player":"pieter","course":"Ranst"}
    url: (api: string, request?: string, params?: Record<string, string>) =>
      `https://www.googleapis.com/oauth2/v3/${api}`,
    appsScriptUrl: () =>
      `https://script.googleapis.com/v1/scripts/${scriptId}:run`,
    appsScriptData: (request: string, params?: Record<string, string>) => ({
      function: request,
      parameters: [params],
      devMode: true,
    }),
    getHeaders: (headers?: { [key: string]: string }) => ({
      headers: { ...defaultHeaders, ...authHeaders, ...headers },
    }),
  });
};

export const DataProvider: FC<DataProviderProps> = ({ children, config }) => {
  const api = useApi(config);

  return <DataContext.Provider value={api}>{children}</DataContext.Provider>;
};
