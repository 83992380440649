import { useState } from "react";
import type { Token, UserProfile } from "../../../types";

import type { AuthApi } from "../AuthProvider";

export const useAuth = (): AuthApi => {
  const [loggedin, setLoggedin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const api = {
    isLoggedin: loggedin,
    isLoggingin: loading,
    login: async () => {
      await setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoggedin(true);
      setLoading(false);
    },
    logout: async () => {
      setLoggedin(false);
    },
    getUser: async () => {
      if (api.isLoggedin) {
        return {
          name: "Pieter",
          email: "pietersoudan@gmail.com",
          picture: "/public/covers/pieter.jpg",
        } as UserProfile;
      } else return undefined;
    },
    token: undefined,
    gapi: undefined,
  };

  return {
    ...api,
  };
};
