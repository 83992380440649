import { Edit } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  AvatarGroup,
  Avatar,
  IconButton,
  Stack,
} from "@mui/material";
import { ScoreRow, ScoreVariant } from "../ScoreCard";
import { range } from "lodash";

export interface FlightCardProps {
  _id: string;
  onClick?: (
    event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>
  ) => void;
  onEdit: () => void;
  players?: { key: string; avatarSrc: string }[];
  nbHoles?: 9 | 18;
  completedHoles?: number[];
  incompleteHoles?: number[];
}

const FlightCard = ({
  onClick,
  onEdit,
  players,
  nbHoles = 9,
  completedHoles = [],
  incompleteHoles = [],
}: FlightCardProps) => {
  const scoreMapper = (i: number) => ({
    variant: (completedHoles?.includes(i)
      ? "complete"
      : incompleteHoles?.includes(i)
      ? "incomplete"
      : "unstarted") as ScoreVariant,
    children: "",
  });
  return (
    <Card sx={{ minWidth: 320 }}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <AvatarGroup max={4}>
              {players?.map(({ avatarSrc, key }) => (
                <Avatar src={avatarSrc} key={key}></Avatar>
              ))}
            </AvatarGroup>
            <Stack
              direction={"column"}
              spacing="1px"
              sx={{
                flex: "1 1 0",
                maxWidth: "240px",
                ml: 2,
                mr: 2,
                alignSelf: "stretch",
                justifyContent: "center",
              }}
            >
              <ScoreRow scores={range(9).map(scoreMapper)} gap={"1px"} />
              {nbHoles === 18 && (
                <ScoreRow scores={range(9, 18).map(scoreMapper)} gap={"1px"} />
              )}
            </Stack>
            <IconButton onClick={() => onEdit()}>
              <Edit />
            </IconButton>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default FlightCard;
