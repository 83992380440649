import { FC, ReactNode } from "react";
import {
  AppBar,
  Button,
  ButtonProps,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import HolePlayerInput, {
  HolePlayerInputProps,
} from "../HolePlayerInput/HolePlayerInput";
import { AlignRight } from "@debaanop/ui-lib";
import { ChevronRight } from "@mui/icons-material";
import HoleSelector, { HoleSelectorProps } from "../HoleSelector/HoleSelector";

export const HoleInputPlayer = (props: HolePlayerInputProps) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        p: 0,
        backgroundColor: "background.default",
      }}
    >
      <HolePlayerInput
        {...props}
        sx={{
          p: 1,
          [theme.breakpoints.up("sm")]: {
            p: 1,
            pr: 2,
          },
        }}
      />
    </Paper>
  );
};
export type HoleInputProps = {
  children: ReactNode;
  holeSelectorProps: HoleSelectorProps;
  buttonProps: ButtonProps;
};

export const HoleInput: FC<HoleInputProps> = ({
  children,
  holeSelectorProps,
  buttonProps,
}) => {
  return (
    <>
      <AppBar sx={{ background: "transparent" }}>
        <HoleSelector {...holeSelectorProps} sx={{ pt: 2, pr: 4, pl: 4 }} />
      </AppBar>
      <Stack sx={{ display: "flex", p: 2 }} gap={2}>
        {children}
        <AlignRight>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ChevronRight />}
            {...buttonProps}
          >
            submit
          </Button>
        </AlignRight>
      </Stack>
    </>
  );
};

HoleInput.displayName = "HoleInput";

export default HoleInput;
