import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { global as GlobalStyles } from "@debaanop/themes-mui";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(GlobalStyles);

import "./App.css";
import AppProviders from "./providers";

import { CourseOverview, CourseDetail, Login } from "./pages";
import Test from "./pages/Test";
import { useAuth } from "./services/auth/AuthProvider";

function App() {
  return (
    <BrowserRouter>
      <AppProviders>
        <Wrapped />
      </AppProviders>
    </BrowserRouter>
  );
}

const AuthCheck = () => {
  const { isLoggedin } = useAuth();
  if (isLoggedin) {
    return <Outlet />;
  }
  return <Login />;
};

function Wrapped() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Routes>
        <Route path="/" element={<AuthCheck />}>
          <Route path="/" element={<CourseOverview />} />
          <Route path="/:course" element={<CourseDetail />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
