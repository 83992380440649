import { useQuery } from "@tanstack/react-query";

import CourseOverviewUI from "./CourseOverview";
import { useApi } from "../../services/api/DataProvider";
import { useNavigate } from "react-router";

const CourseOverview = () => {
  const navigate = useNavigate();
  const { allCourses } = useApi();

  const { data: courses, isLoading } = useQuery({
    queryKey: ["allCourses"],
    queryFn: allCourses,
  });

  console.log({ courses });

  return (
    <>
      <CourseOverviewUI
        courses={courses ?? []}
        onClickCourse={(courseId) => navigate(`/${courseId}`)}
      />
    </>
  );
};

export const Routes = <></>;

export default CourseOverview;
