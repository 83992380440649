import { useQuery } from "@tanstack/react-query";
import { useApi } from "../services/api/DataProvider";

export const useCoursePlayers = (course: string) => {
  const { coursePlayers } = useApi();
  return useQuery({
    queryKey: ["coursePlayers", course || "Ranst"],
    queryFn: () => coursePlayers(course!),
  });
};
