import { ReactNode } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { light } from "@debaanop/themes-mui";

import {
  AppProvider,
  ViewProvider,
  DialogProvider,
  AppLayout,
} from "@debaanop/ui-lib";

import { useView } from "../hooks";

import { CoursePlayer, Hole, Flight } from "../sheets";
import { AuthProvider } from "../services/auth/AuthProvider";
import { DataProvider } from "../services/api/DataProvider";
import { AppProvider as DeBaanOpProvider } from "./appContext";
import ApiClientProvider from "./apiClient";
import { FlightProvider } from "../services/flight/FlightProvider";

const panels = {
  player: {
    Component: CoursePlayer,
  },
  hole: {
    Component: Hole,
  },
  flight: {
    Component: Flight,
  },
};

const Layout = ({ children }: { children: ReactNode }) => {
  const [openPanels, { openPanel, closePanel }] = useView();
  return (
    <AppLayout
      detailViewsProps={{
        components: panels,
        panels: openPanels,
        onClose: closePanel,
      }}
    >
      {children}
    </AppLayout>
  );
};

const AppProviders = ({ children }: { children: ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={light}>
        <AppProvider>
          <ViewProvider>
            <DialogProvider>
              <AuthProvider
                backend="google"
                config={{
                  google: {
                    clientId:
                      "275229002046-7gbijtbhnagjcs6g2dj8ngea604k0dci.apps.googleusercontent.com",
                  },
                }}
              >
                <DataProvider
                  backend="gSheets"
                  config={{
                    gSheets: {
                      // scriptUrl:
                      //   // "https://script.google.com/macros/s/AKfycbzLnJaLduNwni4-LSO2lBbHpT0YAvrubRvxmD1vWKIf/dev",
                      //   "https://script.googleapis.com/v1/scripts/AKfycbyBbq60I5PjCOWLryTPo8BvdLG9j3CbZHQl3xzGEhlmwHuapxIEnqqRQusFfi4tu2tt:run",
                      scriptId:
                        "AKfycbyI1fOjEOuF0OB1VLr4cjQTPCOmJDyR8u0CmVw5HUpqqDoS9naaYiFtS9xEDDG5yNg5VA",
                    },
                  }}
                >
                  <ApiClientProvider>
                    <DeBaanOpProvider>
                      <FlightProvider backend="indexedDB">
                        <Layout>{children}</Layout>
                      </FlightProvider>
                    </DeBaanOpProvider>
                  </ApiClientProvider>
                </DataProvider>
              </AuthProvider>
            </DialogProvider>
          </ViewProvider>
        </AppProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppProviders;
