import type {
  Course,
  PlayerData,
  Hole,
  FlightData,
  UserProfile,
} from "../../../types";
import { useIndexedDBStore } from "use-indexeddb";

import type { Api } from "../DataProvider";
import { holes } from "../../../helpers";
import { useState } from "react";

export const useApi = (): Api => {
  const playerData = useIndexedDBStore<PlayerData>("playerdata");
  const api = {
    getUser: async () => {
      return {
        name: "Pieter",
        email: "pietersoudan@gmail.com",
        picture: "/public/covers/pieter.jpg",
      } as UserProfile;
    },
    test: async () => {
      return "test";
    },
    allCourses: async (): Promise<Course[]> => {
      return ["Ranst", "Edegem"].map((name) => ({ name, id: name, holes: [] }));
    },
    coursePlayers: async (cId: string): Promise<PlayerData[]> => {
      const all = await playerData.getAll();

      return all.filter(({ courseId }) => courseId === cId);
    },
    courseHoles: async (courseId: string): Promise<Hole[]> => {
      return holes;
    },
    submitFlight: async (courseId: string, players: FlightData[]) => {},
  };

  return {
    ...api,
  };
};
