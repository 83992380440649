import { AppBar, Paper, Box } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { CoverView, Selector, SelectorProps } from "@debaanop/ui-lib";

import {
  StrokesDistribution,
  StrokesDistributionProps,
} from "../../components/StrokesDistribution";
import { HoleCard } from "../../components/HoleCard";
import { ScoreCard, ScoreCardProps } from "../../components/ScoreCard";
import type { Hole } from "../../types";
import HoleSelector, {
  HoleSelectorProps,
} from "../../components/HoleSelector/HoleSelector";

type HoleSheetProps = {
  coverUrl?: string;
  title?: string;
  holes: Hole[];
  selectorProps: HoleSelectorProps;
  playerDistributionData: Record<string, StrokesDistributionProps["data"]>;
  onClickPlayerIndex: (id: number) => void;
  scoreCards?: Record<string, ScoreCardProps[]>;
};

const HoleSheet = ({
  coverUrl,
  title,
  holes,
  playerDistributionData,
  onClickPlayerIndex,
  selectorProps,
  scoreCards,
}: HoleSheetProps) => {
  const { value } = selectorProps;

  return (
    <CoverView
      scrollable
      cover={coverUrl}
      title={title}
      stickyContent={
        <AppBar sx={{ p: 0 }}>
          <HoleSelector {...selectorProps} />
        </AppBar>
      }
    >
      <Paper sx={{ m: 2, flex: "1 0 0", height: 400 }}>
        <StrokesDistribution
          padding={0.5}
          data={playerDistributionData[value]}
          onClick={({ index }) => onClickPlayerIndex(index)}
        />
      </Paper>
      <Box sx={{ position: "relative", m: 2, mb: 0 }}>
        <AnimatePresence>
          {Object.keys(scoreCards ?? {}).map(
            (scoreCardIndex, index) =>
              index === parseInt(value) && (
                <motion.div
                  transition={{ ease: "easeOut", duration: 0.3 }}
                  key={value}
                  initial={{ translateX: "-120%" }}
                  animate={{ translateX: 0 }}
                  exit={{ translateX: "120%" }}
                >
                  <Box sx={{ position: "absolute", width: "100%" }}>
                    {scoreCards?.[scoreCardIndex].map((scoreCard) => (
                      <ScoreCard
                        {...scoreCard}
                        sx={{ mb: 2 }}
                        subTitle="last 5 times on this hole"
                      />
                    ))}
                  </Box>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </Box>
    </CoverView>
  );
};

export default HoleSheet;
