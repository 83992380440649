import { Selector, SelectorProps } from "@debaanop/ui-lib";
import { HoleCard, HoleCardProps } from "../HoleCard";

export type HoleSelectorProps = {
  options: Record<string, HoleCardProps>;
} & Omit<SelectorProps, "options">;

const CustomOption =
  ({
    options,
    setMenuOpen,
  }: Pick<HoleSelectorProps, "options" | "setMenuOpen">) =>
  ({ key, value }: { key: string; value: string }) => {
    return (
      <HoleCard
        onClick={() => setMenuOpen(true)}
        {...options[key]}
        sx={{ p: 0, width: "150px", backgroundColor: "transparent" }}
      />
    );
  };

export const HoleSelector = ({
  options,
  setMenuOpen,
  ...props
}: HoleSelectorProps) => (
  <Selector
    {...props}
    renderOption={CustomOption({ options, setMenuOpen })}
    height={58}
    sx={{
      ...props.sx,
    }}
    setMenuOpen={setMenuOpen}
    options={Object.keys(options).reduce(
      (acc, option) => ({
        ...acc,
        [option]: `${options[option].name} (${options[option].par})` ?? option,
      }),
      {} as Record<string, string>
    )}
  />
);

HoleSelector.displayName = "HoleSelector";

export default HoleSelector;
