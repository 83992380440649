import {
  Course,
  PlayerData,
  Hole,
  UserProfile,
  FlightData,
} from "../../../types";

import axios from "axios";

import type { Api } from "../DataProvider";

export type ScriptResult<T> = {
  done: boolean;
  response: {
    type: string;
    result: T;
  };
};

export const useApi = ({
  url,
  appsScriptUrl,
  appsScriptData,
  getHeaders,
}: {
  url: (api: string) => string;
  appsScriptUrl: () => string;
  appsScriptData: (request: string, params?: Record<string, any>) => any;
  getHeaders: (headers?: { [key: string]: string }) => {
    headers?: { [key: string]: string };
  };
}): Api => {
  const api = {
    getUser: async () => {
      const { data } = await axios.get<UserProfile>(url("user"), getHeaders());

      return data;
    },
    test: async () => {
      const { data } = await axios.post<ScriptResult<string>>(
        appsScriptUrl(),
        appsScriptData("testApi"),
        getHeaders()
      );
      return data?.response?.result;
    },
    allCourses: async (): Promise<Course[]> => {
      const { data } = await axios.post<ScriptResult<Course[]>>(
        appsScriptUrl(),
        appsScriptData("getAllCourseInfo"),
        getHeaders()
      );

      return data?.response?.result;
    },
    coursePlayers: async (courseId: string): Promise<PlayerData[]> => {
      const { data } = await axios.post<ScriptResult<PlayerData[]>>(
        appsScriptUrl(),
        appsScriptData("getPlayerData", { course: courseId }),
        getHeaders()
      );
      return data?.response?.result;
    },
    courseHoles: async (courseId: string): Promise<Hole[]> => {
      const { data } = await axios.post<ScriptResult<Hole[]>>(
        appsScriptUrl(),
        appsScriptData("getCourseInfo", { course: courseId }),
        getHeaders()
      );

      return data?.response?.result.map((hole) => ({ ...hole, id: hole.name }));
    },
    submitFlight: async (courseId: string, players: FlightData[]) => {
      const { data } = await axios.post<Hole[]>(
        appsScriptUrl(),
        appsScriptData("submitFlight", { players }),
        getHeaders()
      );
    },
  };

  return {
    ...api,
  };
};
