import { Distribution } from "../../types";

export const emptyD = {
  birdie: 0,
  par: 0,
  bogey: 0,
  doubleBogey: 0,
  worse: 0,
};

export function createD(par: number, strokes: number) {
  const b = (v: boolean): number => (v ? 1 : 0);
  return {
    birdie: b(strokes < par),
    par: b(strokes === par),
    bogey: b(strokes === par + 1),
    doubleBogey: b(strokes === par + 2),
    worse: b(strokes > par + 2),
  };
}

export function addD(d1: Distribution, d2: Distribution) {
  return {
    birdie: d1.birdie + d2.birdie,
    par: d1.par + d2.par,
    bogey: d1.bogey + d2.bogey,
    doubleBogey: d1.doubleBogey + d2.doubleBogey,
    worse: d1.worse + d2.worse,
  };
}

type EDistribution = { label: string } & Distribution;

export function mergeHoles(
  holes: { par: number; strokes: number }[],
  d: EDistribution[]
) {
  return holes.map(({ par, strokes }, i) => ({
    ...d[i],
    ...addD(d[i], createD(par, strokes)),
  }));
}

export function mergePars(
  holes: { par: number; strokes: number }[],
  d: Record<string, EDistribution>
) {
  return holes.reduce(
    (acc, { par, strokes }, i) => ({
      ...acc,
      [par.toString()]: {
        ...(acc[par.toString()] ?? {}),
        ...addD(acc[par.toString()], createD(par, strokes)),
      },
    }),
    d
  );
}
