import { FC } from "react";
import { Stack, StackProps } from "@mui/material";
import { range } from "lodash";
import { Circle, Star } from "@mui/icons-material";

type Variants = "circle" | "star";

export type StrokesExtraProps = {
  amount?: number;
  Icon?: typeof Circle;
  variant?: Variants;
  size?: number;
} & StackProps;

const iconMap: Record<Variants, typeof Circle> = {
  circle: Circle,
  star: Star,
};

export const StrokesExtra: FC<StrokesExtraProps> = (props) => {
  const { amount, Icon, variant, size, ...stackProps } = {
    amount: 0,
    size: 6,
    variant: "circle" as Variants,
    ...props,
  };
  const FinalIcon = Icon ?? iconMap[variant];
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      gap={0.1}
      {...stackProps}
    >
      {range(amount).map((i) => (
        <FinalIcon key={`icon-${i}`} sx={{ width: size, height: size }} />
      ))}
    </Stack>
  );
};

StrokesExtra.displayName = "StrokesExtra";

export default StrokesExtra;
