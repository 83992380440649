import { useState } from "react";
import { Distribution, FlightData, Hole, Player } from "../../types";

import { HoleInputPlayer as HoleInputPlayerUI } from "../../components/HoleInput/HoleInput";
import { useHoleStrokesUpdate } from "../../mutations/flights";
import usePlayerDistribution from "../../services/distribution/usePlayerDistribution";
import useHoleData from "../../services/distribution/useHoleData";
import { calculateAverage } from "../../helpers";
import { usePanel } from "../../hooks";
import { holeMapper } from "../../components/ScoreCard";

import {
  HolePlayerInputProps,
  useHolePlayerInput,
  useHolePlayerInputProps,
} from "../../components/HolePlayerInput/HolePlayerInput";
import { useSelector } from "@debaanop/ui-lib";

type UseHoleInputProps = {
  course: string;
  extraStrokesMemo?: Record<Player, Record<number, number>>;
  holes: Hole[];
  flight?: FlightData[];
};

const HoleInputPlayer = ({
  hole,
  courseHandicap,
  nbOfHoles,
  strokes,
  onChange,
  ...props
}: useHolePlayerInputProps &
  Omit<
    HolePlayerInputProps,
    "strokesExtraProps" | "sf" | "strokeInputProps" | "onChange"
  >) => {
  const mapped = useHolePlayerInput({
    hole,
    courseHandicap,
    nbOfHoles,
    strokes,
    onChange,
  });
  return <HoleInputPlayerUI {...props} {...mapped} />;
};

export const useHoleInput = ({
  course,
  extraStrokesMemo,
  holes,
  flight,
}: UseHoleInputProps) => {
  const openPanel = usePanel();

  const holeOptions = holes.reduce(
    (memo, hole, index) => ({ ...memo, [`${index}`]: hole }),
    {} as Record<string, Hole>
  );

  // setup mutation to save strokes
  const { mutate: updateStrokes } = useHoleStrokesUpdate(course);

  // get stats for holes and players
  const playerDistributionData = usePlayerDistribution(course);
  const holeData = useHoleData(course);

  const [strokeData, setStrokeData] = useState<
    Record<number, Record<string, number>>
  >({});

  const selectorProps = useSelector({
    options: Object.keys(holeOptions).reduce(
      (acc, option) => ({
        ...acc,
        [option]: holeOptions[option].name ?? "",
      }),
      {} as Record<string, string>
    ),
    cyclic: true,
  });
  const holeIndex = parseInt(selectorProps.value);
  const hole = holes[holeIndex];

  const updatePlayerStrokes = (player: string) => (strokes: number) => {
    setStrokeData((prev) => ({
      ...prev,
      [holeIndex]: {
        ...prev[holeIndex],
        [player]: strokes,
      },
    }));
  };
  const resetHole = () =>
    setStrokeData((prev) => ({
      ...prev,
      [holeIndex]: {},
    }));
  const isHoleDirty = Object.keys(strokeData[holeIndex] ?? {}).length > 0;
  const isHoleSubmitted = flight?.[0]?.strokes?.[holeIndex] !== undefined;
  const mergedHoleStrokes = (holeIndex: number) =>
    flight!.reduce(
      (acc, { player, strokes }) => ({
        ...acc,
        [player]:
          strokeData[holeIndex]?.[player] ??
          strokes[holeIndex] ??
          hole.par + (extraStrokesMemo?.[player][holeIndex] ?? 0),
      }),
      {} as Record<Player, number>
    );

  const commitHole = () => {
    updateStrokes(
      {
        holeIndex,
        strokes: mergedHoleStrokes(holeIndex),
      },
      {
        onError: () => {
          // TODO: cancel reset and go back to previous hole?
        },
      }
    );

    // dont wait for mutation to complete, to have instant feedback
    // mutation is doing an optimistic update anyway
    resetHole();
    // go to next hole, if any
    if (!isHoleSubmitted) {
      selectorProps.onClickNext();
    }
  };

  return {
    holeSelectorProps: { ...selectorProps, options: holeOptions },
    buttonProps: {
      disabled: !isHoleDirty && isHoleSubmitted,
      onClick: () => {
        commitHole();
      },
    },
    children: (
      <>
        {flight?.map(({ player, courseHandicap, strokes }) => (
          <HoleInputPlayer
            key={player}
            hole={hole}
            courseHandicap={courseHandicap}
            nbOfHoles={holes.length}
            strokes={
              strokeData[holeIndex]?.[player] ??
              strokes[holeIndex] ??
              hole.par + (extraStrokesMemo?.[player][holeIndex] ?? 0)
            }
            onChange={updatePlayerStrokes(player)}
            onClickPlayer={() => {
              openPanel("hole", { id: holeIndex.toString(), course });
            }}
            playerAvatarSrc={`/covers/${player}.jpg`}
            bestScoreProps={holeMapper({
              ...holeData[holeIndex.toString()][player][0],
            })}
            strokesDistribution={{
              data: [playerDistributionData[holeIndex][player]],
            }}
            averageScoreProps={{
              variant: "bogey",
              children: calculateAverage(
                playerDistributionData[holeIndex][player] as Distribution,
                hole.par
              ).toFixed(1),
            }}
          />
        ))}
      </>
    ),
  };
};
