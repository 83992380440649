import { random, range } from "lodash";
import { Hole, ScoreVariant, PlayerHole, Distribution } from "./types";

export const players = ["pieter", "chris", "tom"];

export const holes: Hole[] = range(9).map((i) => ({
  id: `hole${i + 1}`,
  par: random(3, 5),
  si: random(1, 10),
  name: `Hole ${i + 1}`,
}));

export const randomDistribution = () => ({
  birdie: random(0, 10),
  par: random(10, 30),
  bogey: random(20, 40),
  doubleBogey: random(10, 30),
  worse: random(0, 30),
});

const holeVariants = [
  "birdie",
  "par",
  "bogey",
  "doubleBogey",
  "worse",
] as const;

const variantMap = {
  birdie: -1,
  par: 0,
  bogey: 1,
  doubleBogey: 2,
  worse: 3,
} as const;

export const holeVariant = (strokes: number, par: number): ScoreVariant =>
  holeVariants[strokes - par + 1] ?? "worse";

export const variantReducer = (
  acc: Record<ScoreVariant, number>,
  { strokes, par }: PlayerHole
): Record<ScoreVariant, number> => {
  const v = holeVariant(strokes, par);
  return {
    ...acc,
    [v]: acc[v] + 1,
  };
};

export const extraStrokes = (
  courseHandicap: number,
  nbOfHoles: number,
  si: number
) =>
  Math.floor(courseHandicap / nbOfHoles) +
  (si <= courseHandicap - Math.floor(courseHandicap / nbOfHoles) * nbOfHoles
    ? 1
    : 0);

export const stableFord = (par: number, hc: number, strokes: number) =>
  Math.max(0, 2 + par + hc - strokes);

export const stableFordReducer = (
  acc: number,
  { par, hc, strokes }: PlayerHole
): number => acc + (strokes ? stableFord(par, hc, strokes) : 0);

export const holePlayerMapper =
  (courseHandicap: number, nbOfHoles: number) =>
  (hole: Hole): ((strokes: number) => PlayerHole) =>
  (strokes) => ({
    ...hole,
    strokes,
    hc: extraStrokes(courseHandicap, nbOfHoles, hole.si),
  });

export const calculateAverage = (data: Distribution, par: number): number => {
  const { total, strokes } = Object.keys(data)
    .filter((key) => key !== "label")
    .reduce(
      (acc, v) => ({
        ...acc,
        strokes:
          acc.strokes +
          (par + variantMap[v as ScoreVariant]) * data[v as ScoreVariant],
        total: acc.total + data[v as ScoreVariant],
      }),
      { strokes: 0, total: 0 }
    );
  return strokes / total;
};
