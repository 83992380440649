import { usePanel, useActivePanel } from "../../hooks";

import CoursePlayerSheet from "./CoursePlayerSheet";

import { players } from "../../helpers";

import { useManagedSelector } from "@debaanop/ui-lib";
import useHoleDistribution from "../../services/distribution/useHoleDistribution";
import useParDistribution from "../../services/distribution/useParDistribution";
import usePlayerData from "../../services/distribution/usePlayerData";
import { Player } from "../../types";

import { scoreCardMapper } from "../../components/ScoreCard";

export type CoursePlayerContainerProps = {
  id?: string;
  course?: string;
};

export const CoursePlayerContainer = ({ id }: CoursePlayerContainerProps) => {
  const { course } = useActivePanel("player");
  const openPanel = usePanel();
  const selectorProps = useManagedSelector({
    options: players.reduce((memo, p) => ({ ...memo, [p]: p }), {}),
    selected: id!,
    onChange: (id: string) => {
      openPanel("player", { id, course });
    },
    cyclic: true,
  });

  const holeDistributionData = useHoleDistribution(course as string);
  const parDistributionData = useParDistribution(course as string);
  const playerData = usePlayerData(course as string);
  return (
    <CoursePlayerSheet
      title={course as string}
      selectorProps={selectorProps}
      holeDistributionData={holeDistributionData}
      parDistributionData={parDistributionData}
      onClickHoleIndex={(index) => {
        openPanel("hole", { id: index.toString(), course });
      }}
      scoreCards={(Object.keys(playerData) as Player[]).reduce(
        (memo, player) => ({
          ...memo,
          [player]: playerData[player].map(scoreCardMapper),
        }),
        {}
      )}
    />
  );
};

export default CoursePlayerContainer;
