import { createContext, FC, ReactNode, useContext } from "react";
import type { Course, PlayerData, FlightData, UserProfile } from "../../types";

import {
  DataProvider as GSheetsProvider,
  useApi as useGSheetsApi,
  GSheetsConfig,
} from "./gSheets/GSheetsDataProvider";

import {
  DataProvider as MockProvider,
  useApi as useMockApi,
} from "./mock/MockDataProvider";

export interface Api {
  test: () => Promise<string>;
  getUser: () => Promise<UserProfile>;
  allCourses: () => Promise<Course[]>;
  // allPlayers: () => Promise<Player[]>;
  coursePlayers: (courseId: string) => Promise<PlayerData[]>;
  submitFlight: (courseId: string, players: FlightData[]) => Promise<void>;
  // courseHoles: (courseId: string) => Promise<Hole[]>;
  // getCourseResults: (courseId: string) => Promise<PlayerData[]>;
  // getPlayerCourseResults: (
  //   courseId: string,
  //   playerId: string
  // ) => Promise<PlayerData[]>;
}

export const DataContext = createContext<Api | null>(null);

export type DataProviderProps = {
  children: ReactNode;
  backend: "mock" | "gSheets";
  config: {
    gSheets: GSheetsConfig;
  };
};

export const DataProvider: FC<DataProviderProps> = ({
  children,
  backend,
  config,
}) => {
  if (backend === "mock") {
    return (
      <MockProvider>
        <MockWrapper>{children}</MockWrapper>
      </MockProvider>
    );
  }

  return (
    <GSheetsProvider config={config.gSheets}>
      <GSheetsWrapper config={config.gSheets}>{children}</GSheetsWrapper>
    </GSheetsProvider>
  );
};

const GSheetsWrapper: FC<{ children: ReactNode; config: GSheetsConfig }> = ({
  children,
  config,
}) => {
  const api = useGSheetsApi(config);

  return <DataContext.Provider value={api}>{children}</DataContext.Provider>;
};

const MockWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const api = useMockApi();

  return <DataContext.Provider value={api}>{children}</DataContext.Provider>;
};

export const useApi = (): Api => {
  const api = useContext(DataContext);
  if (!api) {
    throw Error("not used within proper provider");
  }

  return api;
};
