import { Selector, SelectorProps } from "@debaanop/ui-lib";
import { Avatar, Stack, Typography } from "@mui/material";
import { CSSProperties } from "react";

export type AvatarSelectorProps = {
  height?: CSSProperties["height"];
  onClick?: (key: string) => void;
} & Omit<SelectorProps, "onClick">;

const CustomOption =
  ({
    height = 240,
    onClick,
  }: {
    height?: AvatarSelectorProps["height"];
    onClick?: AvatarSelectorProps["onClick"];
  }) =>
  ({ key, value }: { key: string; value: string }) => {
    return (
      <Stack
        sx={{
          justifyContent: "center",
          height: 0,
          gap: 2,
          transformOrigin: "center center",
          transform: "translateY(15px)",
        }}
      >
        <Avatar
          src={value}
          key={key}
          onClick={() => {
            onClick?.(key);
          }}
          sx={{
            width: height,
            height,
            transformOrigin: "center center",
            border: "4px solid white",
            boxShadow: "0px 4px 23px rgba(0, 0, 0, 0.09)",
          }}
        />
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          {key.toLocaleUpperCase()}
        </Typography>
      </Stack>
    );
  };

const AvatarSelector = ({ height, onClick, ...props }: AvatarSelectorProps) => (
  <Selector
    {...props}
    renderOption={CustomOption({ height, onClick })}
    height={0}
    sx={{
      overflow: "visible",
      ...props.sx,
    }}
  />
);

export default AvatarSelector;
