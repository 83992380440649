import { createContext, FC, ReactNode, useEffect } from "react";
import setupIndexedDB from "use-indexeddb";

import type { Api } from "../DataProvider";
import { useApi } from "./api";

export { useApi } from "./api";

const idbConfig = {
  databaseName: "deBaanOpMock",
  version: 2,
  stores: [
    {
      name: "playerdata",
      id: { keyPath: "id" },
      indices: [
        { name: "id", keyPath: "id", options: { unique: true } },
        { name: "course", keyPath: "course.id", options: { unique: false } },
        { name: "player", keyPath: "player.id", options: { unique: false } },
      ],
    },
  ],
};

export const DataContext = createContext<Api | null>(null);

export interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: FC<DataProviderProps> = ({ children }) => {
  // TODO: move this to do this only once for all dbs
  useEffect(() => {
    setupIndexedDB(idbConfig)
      .then(() => {
        // check if empty, if so, fill with mock data
      })
      .catch(() => {
        // TODO: what if indexedDB couldnt be initialized
      });
  }, []);

  const api = useApi();

  return <DataContext.Provider value={api}>{children}</DataContext.Provider>;
};
