import { useMemo } from "react";
import { useAppState } from "../../providers/appContext";
import { PlayerData } from "../../types";

import { useCoursePlayers } from "../../queries/playerData";

const e = {
  pieter: [],
  chris: [],
  tom: [],
};

type K = keyof typeof e;

// group all results by player
const useSlicedData = (course: string) => {
  const { on, best, size, percent } = useAppState();

  const { data, isLoading } = useCoursePlayers(course || "Ranst");

  // first group by player
  const grouped = useMemo(
    () =>
      data?.reduce(
        (acc, result) => ({
          ...acc,
          [result.player]: [...acc[result.player], result],
        }),
        e
      ) ?? e,
    [data, isLoading]
  );

  // reslice the data
  return useMemo(() => {
    const hasData = data && data.length > 0;
    if (!hasData) return [];
    if (!on) return data;
    return Object.keys(grouped).reduce((acc, player) => {
      const count = grouped[player as K]?.length ?? 0;
      const windowSize = Math.min(count, size);
      const rest = Math.max(0, count - windowSize);
      const start = Math.floor((percent / 100) * rest);
      const end = start + windowSize;
      return [
        ...acc,
        ...(grouped[player as K]
          ?.slice(start, end)
          .sort((a: PlayerData, b: PlayerData) => b.strokes - a.strokes)
          .slice(-best) ?? []),
      ];
    }, [] as PlayerData[]);
  }, [on, best, size, percent, grouped]);
};

export default useSlicedData;
