import { useMemo } from "react";
import { formatRelative, parseISO } from "date-fns";
import { nlBE } from "date-fns/locale";

import FlightSheet from "./FlightSheet";
import { Player } from "../../types";
import { holeMapper } from "../../components/ScoreCard";
import { useAllCourses, useCourseFlight } from "../../queries";

import { extraStrokes, stableFordReducer, variantReducer } from "../../helpers";
import { useActivePanel } from "../../hooks";

import { emptyD } from "../../services/distribution/helpers";
import { useHoleInput } from "./useHoleInput";
import { useFlightSubmit } from "../../mutations/flights";

const FlightSheetContainer = () => {
  const { course } = useActivePanel("flight");

  const { mutate: submitFlight } = useFlightSubmit(course as string);

  // get flight info for course
  const { data: flight } = useCourseFlight(course as string);

  // get holeinfo for course
  const { data: courses } = useAllCourses();
  const holes = courses?.find((c) => c.id === course)?.holes ?? [];

  const extraStrokesMemo = useMemo(() => {
    if (holes && flight) {
      return flight.reduce((acc, { player, courseHandicap }, index) => {
        return {
          ...acc,
          [player]: holes.reduce(
            (acc2, hole, index) => ({
              ...acc2,
              [index]: extraStrokes(courseHandicap, holes.length, hole.si),
            }),
            {} as Record<number, number>
          ),
        };
      }, {} as Record<Player, Record<number, number>>);
    }
  }, [flight?.length, holes.length]);

  const holeInputProps = useHoleInput({
    course: course as string,
    extraStrokesMemo,
    holes,
    flight,
  });

  const allFlightsComplete = useMemo(() => {
    if (flight) {
      return flight.every(
        ({ strokes }) => Object.values(strokes).length === holes.length
      );
    }
  }, [flight?.length, holes.length]);

  return (
    <FlightSheet
      submitDisabled={!allFlightsComplete}
      submitScores={() => {
        submitFlight(flight!);
      }}
      holeInputProps={holeInputProps}
      scoreCards={
        flight?.map(({ strokes, player, date }) => {
          const mappedHoles = holes.map((hole, index) => ({
            ...hole,
            strokes: strokes[index],
            hc: extraStrokesMemo?.[player][index] ?? 0,
          }));

          return {
            _id: player,
            key: player,
            title: player,
            subTitle: formatRelative(parseISO(date), new Date(), {
              locale: nlBE,
            }),
            strokesDistribution:
              Object.values(strokes).length > 0
                ? {
                    data: [
                      holes
                        .filter((_, index) => strokes[index] > 0)
                        .map((hole, index) => ({
                          par: hole.par,
                          si: hole.si,
                          hc: extraStrokesMemo?.[player][index] ?? 0,
                          strokes: strokes[index],
                        }))
                        .reduce(variantReducer, emptyD),
                    ],
                  }
                : undefined,
            scores: mappedHoles.slice(0, 9).map(holeMapper),
            scores2:
              mappedHoles.length > 9
                ? mappedHoles.slice(9).map(holeMapper)
                : undefined,
            sf: mappedHoles.reduce(stableFordReducer, 0),
            sf1:
              mappedHoles.length > 9 && strokes[9] > 0
                ? mappedHoles.slice(0, 9).reduce(stableFordReducer, 0)
                : undefined,
            sf2:
              mappedHoles.length > 9 && strokes[9] > 0
                ? mappedHoles.slice(9).reduce(stableFordReducer, 0)
                : undefined,
            strokes: mappedHoles.reduce(
              (acc, hole) => acc + (hole.strokes ?? 0),
              0
            ),
          };
        }) ?? []
      }
    />
  );
};

export default FlightSheetContainer;
