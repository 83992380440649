import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { transparent } from "@debaanop/themes-mui";
import {
  StrokesDistribution,
  StrokesDistributionProps,
} from "../../components/StrokesDistribution";
import { Flex, useManagedSelector } from "@debaanop/ui-lib";
import useMeasure from "react-use-measure";
import { Add, ChevronLeft, Settings } from "@mui/icons-material";
import { useRef, useState } from "react";
import SlidingWindow, {
  SlidingWindowProps,
} from "../../components/SlidingWindowConfig/SlidingWindowConfig";
import PhotoBackground from "../../components/PhotoBackground/PhotoBackground";
import { ScoreCard, ScoreCardProps } from "../../components/ScoreCard";
import AvatarSelector from "../../components/AvatarSelector/AvatarSelector";
import { Player } from "../../types";
import {
  FlightDetails,
  FlightDetailsProps,
} from "../../components/FlightDetails";
import { FlightCard, FlightCardProps } from "../../components/FlightCard";

export type CoursePageProps = {
  title: string;
  playerDistributionData: StrokesDistributionProps["data"];
  onClickPlayerIndex: (id: number) => void;
  onOpenPlayer: (id: string) => void;
  onGoBack: () => void;
  scoreCard?: ScoreCardProps;
  player: Player;
  setPlayer: (id: Player) => void;
  slidingWindowProps: SlidingWindowProps;
  flightCardProps?: Omit<FlightCardProps, "onEdit">;
  flightDetailsProps: FlightDetailsProps;
  flightDialogOpen: boolean;
  setFlightDialogOpen: (open: boolean) => void;
};

const CoursePage = ({
  title,
  playerDistributionData,
  onClickPlayerIndex,
  onOpenPlayer,
  onGoBack,
  scoreCard,
  player,
  setPlayer,
  slidingWindowProps,
  flightCardProps,
  flightDetailsProps,
  flightDialogOpen,
  setFlightDialogOpen,
}: CoursePageProps) => {
  const [ref1, { width: w1, height: h1 }] = useMeasure();
  const [ref2, { width: w2, height: h2 }] = useMeasure();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [configOpen, setConfigOpen] = useState<boolean>(false);

  const handleClose = () => {
    setConfigOpen(false);
  };
  const avatarSelectorProps = useManagedSelector({
    options: {
      pieter: `/covers/pieter.jpg`,
      chris: `/covers/chris.jpg`,
      tom: `/covers/tom.jpg`,
    },
    cyclic: true,
    onChange: (id: string) => {
      setPlayer(id as Player);
    },
    selected: player,
  });

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={buttonRef?.current}
        open={configOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SlidingWindow {...slidingWindowProps} sx={{ width: "340px" }} />
      </Menu>

      <Dialog
        open={flightDialogOpen}
        onClose={() => setFlightDialogOpen(false)}
        PaperProps={{ sx: { backgroundColor: "background.default" } }}
      >
        <DialogTitle>Create new Flight</DialogTitle>
        <DialogContent>
          <FlightDetails {...flightDetailsProps} />
        </DialogContent>
      </Dialog>

      <ThemeProvider theme={transparent}>
        <PhotoBackground src={`/covers/defaultCourse2.jpg`} masked>
          <Flex fullColumn>
            <AppBar position="relative" style={{ flex: "1 0 48", zIndex: 1 }}>
              <Toolbar ref={ref2}>
                <IconButton onClick={() => onGoBack()}>
                  <ChevronLeft />
                </IconButton>
                <Typography>{title}</Typography>
                <Box sx={{ flex: "1 1 0" }}></Box>
                <IconButton onClick={() => setConfigOpen(true)} ref={buttonRef}>
                  <Settings />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Flex>
          <Flex
            fullColumn
            sx={{
              position: "absolute",
              minWidth: "400px",
              width: `${Math.min(w2, 800)}px`,
              maxWidth: `100vw`,
              bottom: 0,
              right: 0,
              justifyContent: "flex-end",
              flexDirection: "column-reverse",
              alignItems: "stretch",
              p: 4,
              pb: 12,
            }}
          >
            <Box
              sx={{ flex: "1 0 0", display: "flex", justifyContent: "center" }}
              ref={ref1}
            >
              <AvatarSelector
                {...avatarSelectorProps}
                sx={{
                  mt: "80px",
                  width: Math.min(h1, 220) + 140,
                  alignSelf: "center",
                }}
                height={Math.min(h1, 220)}
                onClick={() => onOpenPlayer(player)}
              />
            </Box>
            <Box
              sx={{ flex: "1 1 auto", display: "flex" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {flightCardProps ? (
                <FlightCard
                  {...flightCardProps}
                  onEdit={() => setFlightDialogOpen(true)}
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setFlightDialogOpen(true)}
                  startIcon={<Add />}
                >
                  New Flight
                </Button>
              )}
            </Box>
            <Paper sx={{ height: 200, mb: 4 }}>
              <StrokesDistribution
                layout="horizontal"
                data={playerDistributionData}
                onClick={({ index }) => onClickPlayerIndex(index)}
              />
            </Paper>
            {scoreCard && <ScoreCard {...scoreCard} />}
          </Flex>
        </PhotoBackground>
      </ThemeProvider>
    </>
  );
};

export default CoursePage;
