import { createContext, FC, ReactNode, useContext, useState } from "react";
import { useSlidingWindow } from "../components/SlidingWindowConfig/SlidingWindowConfig";

export interface IAppStateContext {
  best: number;
  size: number;
  percent: number;
  on: boolean;
}

export interface IAppDispatchContext {
  onChangeBest: (v: number) => void;
  onChangeSize: (v: number) => void;
  onChangePercent: (v: number) => void;
  onToggle: (v: boolean) => void;
}

export const AppStateContext = createContext<IAppStateContext | null>(null);
export const AppDispatchContext = createContext<IAppDispatchContext | null>(
  null
);

export type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const {
    best,
    size,
    percent,
    on,
    onToggle,
    onChangeBest,
    onChangePercent,
    onChangeSize,
  } = useSlidingWindow();
  return (
    <AppStateContext.Provider
      value={{
        best,
        size,
        percent,
        on,
      }}
    >
      <AppDispatchContext.Provider
        value={{
          onChangeBest,
          onChangePercent,
          onChangeSize,
          onToggle,
        }}
      >
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export const useAppState = (): IAppStateContext => {
  const appState = useContext(AppStateContext);
  if (!appState) {
    throw Error("not used within proper provider");
  }
  return appState;
};

export const useAppDispatch = (): IAppDispatchContext => {
  const appDispatch = useContext(AppDispatchContext);
  if (!appDispatch) {
    throw Error("not used within proper provider");
  }
  return appDispatch;
};

export const useApp = (): {
  state: IAppStateContext;
  dispatch: IAppDispatchContext;
} => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  return { state, dispatch };
};
