import { FC, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  ToolbarProps,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import StrokeInput, { StrokeInputProps } from "../StrokeInput/StrokeInput";
import StrokesExtra, { StrokesExtraProps } from "../StrokesExtra/StrokesExtra";
import { Score, ScoreProps, holeMapper } from "../ScoreCard";
import { useManagedSelector } from "@debaanop/ui-lib";
import { Hole } from "../../types";
import { range } from "lodash";
import { extraStrokes, stableFord } from "../../helpers";
import {
  StrokesDistribution,
  StrokesDistributionProps,
} from "../StrokesDistribution";

export type useHolePlayerInputProps = {
  hole: Hole;
  courseHandicap: number;
  nbOfHoles: number;
  strokes: number;
  onChange: (strokes: number) => void;
};

export const useHolePlayerInput: (
  props: useHolePlayerInputProps
) => Pick<
  HolePlayerInputProps,
  "strokesExtraProps" | "sf" | "strokeInputProps"
> = ({ hole, courseHandicap, nbOfHoles, strokes, onChange }) => {
  const hc = extraStrokes(courseHandicap, nbOfHoles, hole.si);

  const options = range(9).reduce(
    (acc, i) => ({
      ...acc,
      [`${i + 1}`]: holeMapper({
        ...hole,
        strokes: i + 1,
        hc,
      }),
    }),
    {}
  );
  const o = {
    options: Object.keys(options).reduce(
      (acc, option) => ({ ...acc, [option]: option }),
      {} as Record<string, string>
    ),
    selected: strokes.toString(),
    onChange: (v: string) => onChange(parseInt(v)),
    cyclic: false,
  };
  const selector = useManagedSelector(o);

  return {
    strokesExtraProps: {
      amount: hc,
    },
    sf: stableFord(hole.par, hc, parseInt(selector.value)),
    strokeInputProps: { ...selector, options },
  };
};

export type HolePlayerInputProps = {
  onClickPlayer: () => void;
  playerAvatarSrc: string;
  strokeInputProps: StrokeInputProps;
  strokesExtraProps?: StrokesExtraProps;
  bestScoreProps?: ScoreProps;
  averageScoreProps?: ScoreProps;
  strokesDistribution?: StrokesDistributionProps;
  sf?: number;
} & Omit<ToolbarProps, "children">;

export const HolePlayerInput: FC<HolePlayerInputProps> = ({
  onClickPlayer,
  playerAvatarSrc,
  strokeInputProps,
  strokesExtraProps,
  bestScoreProps,
  averageScoreProps,
  strokesDistribution,
  sf,
  ...toolbarProps
}) => {
  // const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Stack>
      <Toolbar
        {...toolbarProps}
        sx={{
          justifyContent: "space-between",
          p: 1,
          pr: 2,
          ...toolbarProps.sx,
        }}
      >
        <IconButton
          onClick={() => {
            onClickPlayer();
          }}
        >
          <Avatar src={playerAvatarSrc}></Avatar>
        </IconButton>
        <StrokesExtra {...strokesExtraProps} />
        <Stack sx={{ width: 20, ml: 1 }} gap={0.5}>
          {bestScoreProps && <Score {...bestScoreProps} />}
          {averageScoreProps && (
            <Score
              {...averageScoreProps}
              sx={{ backgroundColor: "transparent" }}
            />
          )}
        </Stack>
        <Box sx={{ flex: "1 1 0", display: "flex", justifyContent: "center" }}>
          <StrokeInput
            {...strokeInputProps}
            sx={{ minWidth: 160 }}
          ></StrokeInput>
        </Box>

        <Avatar
          variant="rounded"
          sx={{
            width: 40,
            height: 30,
            fontSize: "1em",
            fontWeight: 800,
            backgroundColor: "secondary.main",
          }}
        >
          {sf}
        </Avatar>
        {/* <IconButton onClick={() => setMenuOpen(!menuOpen)}>
          <ChevronRight
            fontSize="small"
            style={{
              transform: menuOpen ? "rotate(-90deg)" : "rotate(90deg)",
            }}
          />
        </IconButton> */}
      </Toolbar>
      {strokesDistribution && (
        <Box
          sx={{
            width: "100%",
            height: 6,
            m: 0,
            mt: -2,
            p: 0,
            pl: 2,
            pr: 2,
            mb: 2,
          }}
        >
          <StrokesDistribution
            {...strokesDistribution}
            layout="horizontal"
            noLabels
            noPadding
            exactValues
          />
        </Box>
      )}
    </Stack>
  );
};

HolePlayerInput.displayName = "HolePlayerInput";

export default HolePlayerInput;
