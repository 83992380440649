import { createContext, FC, ReactNode, useEffect } from "react";

import type { AuthApi } from "../AuthProvider";
import { useAuth } from "./api";

export { useAuth } from "./api";

export const MockAuthContext = createContext<AuthApi | null>(null);

export interface MockAuthProviderProps {
  children: ReactNode;
}

export const MockAuthProvider: FC<MockAuthProviderProps> = ({ children }) => {
  const api = useAuth();

  return (
    <MockAuthContext.Provider value={api}>{children}</MockAuthContext.Provider>
  );
};
