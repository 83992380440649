import { useMemo } from "react";

import { Player, Distribution } from "../../types/course";
import { emptyD, mergeHoles } from "./helpers";
import useSlicedData from "./useSlicedData";
import { useAllCourses } from "../../queries";

type HoleDistribution = Distribution & { label: string };

const useHoleDistribution = (
  course = "Ranst"
): Record<Player, HoleDistribution[]> => {
  const { data: courses } = useAllCourses();
  const playerData = useSlicedData(course);

  const holes = (courses?.find((c) => c.id === course)?.holes ?? []).map(
    ({ par }, i) => ({ ...emptyD, label: `Hole ${i + 1} (${par})` })
  );

  const players = {
    pieter: holes,
    chris: holes,
    tom: holes,
  };

  const d = useMemo(
    () =>
      playerData?.reduce(
        (acc, p) => ({
          ...acc,
          [p.player]: mergeHoles(p.holes, acc[p.player]),
        }),
        players
      ) ?? players,
    [playerData]
  );
  return d;
};
export default useHoleDistribution;
