import { useMemo } from "react";

import { Distribution } from "../../types/course";
import { addD, emptyD, createD } from "./helpers";
import useSlicedData from "./useSlicedData";
import { useAllCourses } from "../../queries";

type PlayerDistribution = Distribution & { label: string };

const usePlayerDistribution = (
  course = "Ranst"
): Record<string, PlayerDistribution>[] => {
  const { data: courses } = useAllCourses();

  const playerData = useSlicedData(course);

  const players = {
    pieter: { ...emptyD, label: `Pieter` },
    chris: { ...emptyD, label: `Chris` },
    tom: { ...emptyD, label: `Tom` },
  };

  const holes = (courses?.find((c) => c.id === course)?.holes ?? []).map(
    () => players
  );

  const d = useMemo(
    () =>
      playerData?.reduce(
        (acc, p) =>
          acc.map((hole, i) => ({
            ...hole,
            [p.player]: {
              ...hole[p.player],
              ...addD(
                createD(p.holes[i].par, p.holes[i].strokes),
                hole[p.player]
              ),
            },
          })),
        holes
      ) ?? holes,
    [playerData]
  );
  return d;
};

export default usePlayerDistribution;
