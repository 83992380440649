import { useMemo } from "react";

import { Player, PlayerData } from "../../types/course";
import useSlicedData from "./useSlicedData";

const usePlayerData = (course = "Ranst"): Record<Player, PlayerData[]> => {
  const playerData = useSlicedData(course);

  const players = {
    pieter: [],
    chris: [],
    tom: [],
  } as Record<Player, PlayerData[]>;

  const d = useMemo(
    () =>
      playerData?.reduce(
        (acc, p) => ({
          ...acc,
          [p.player]: [...acc[p.player], p],
        }),
        players
      ) ?? players,
    [playerData]
  );

  return Object.keys(d).reduce(
    (acc, player) => ({
      ...acc,
      [player]: d[player as Player]
        // .sort((a, b) => a.date.localeCompare(b.date))
        .slice(-5)
        .reverse(),
    }),
    players as Record<Player, PlayerData[]>
  );
};

export default usePlayerData;
