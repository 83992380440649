import type { FlightData, Player } from "../../../types";
import { useIndexedDBStore } from "use-indexeddb";

import type { Api } from "../FlightProvider";

export const useApi = (): Api => {
  const flightData = useIndexedDBStore<FlightData>("flightdata");

  const api = {
    courseFlight: async (cId: string): Promise<FlightData[]> => {
      return (await flightData.getAll()).filter(
        ({ courseId }) => courseId === cId
      );
    },
    updateFlight: async (
      cId: string,
      handicaps: Record<Player, number>
    ): Promise<FlightData[]> => {
      const date = new Date().toISOString();
      const flights = await api.courseFlight(cId);
      // remove missing flights
      await Promise.all(
        flights.map(({ id, player }) => {
          if (!handicaps[player]) {
            return flightData.deleteByID(id);
          }
          return new Promise<void>((resolve) => resolve());
        })
      );
      await Promise.all(
        Object.keys(handicaps).map((player) => {
          const existing = flights.find(({ player: p }) => p === player);
          // add new flights
          if (!existing) {
            const flight = {
              id: `${cId}-${player}`,
              courseId: cId,
              player: player as Player,
              courseHandicap: handicaps[player as Player],
              date,
              strokes: {},
            };
            return flightData.add(flight);
          } else {
            // update existing flights
            return flightData.update({
              ...existing,
              courseHandicap: handicaps[player as Player],
            });
          }
        })
      );
      return api.courseFlight(cId);
    },
    updateHoleStrokes: async (
      cId: string,
      holeIndex: number,
      strokes: Record<Player, number>
    ): Promise<FlightData[]> => {
      const flights = await api.courseFlight(cId);
      await Promise.all(
        Object.keys(strokes).map((player) => {
          const flight = flights.find(({ player: p }) => p === player);
          if (!flight) {
            return new Promise<void>((resolve) => resolve());
          }
          return flightData.update({
            ...flight,
            strokes: {
              ...flight.strokes,
              [holeIndex]: strokes[player as Player],
            },
          });
        })
      );
      return api.courseFlight(cId);
    },
  };

  return {
    ...api,
    createFlight: api.updateFlight,
  };
};
