import { useMemo } from "react";

import { Player, Distribution } from "../../types/course";
import { emptyD, mergePars } from "./helpers";
import useSlicedData from "./useSlicedData";
import { useAllCourses } from "../../queries";

type HoleDistribution = Distribution & { label: string };

const useParDistribution = (
  course = "Ranst"
): Record<Player, HoleDistribution[]> => {
  const { data: courses } = useAllCourses();

  const playerData = useSlicedData(course);

  const pars = (courses?.find((c) => c.id === course)?.holes ?? []).reduce(
    (acc, { par }) => ({
      ...acc,
      [par.toString()]: { ...emptyD, label: `par ${par}` },
    }),
    {} as Record<string, HoleDistribution>
  );

  const players = {
    pieter: pars,
    chris: pars,
    tom: pars,
  };

  const d = useMemo(
    () =>
      playerData?.reduce(
        (acc, p) => ({
          ...acc,
          [p.player]: mergePars(p.holes, acc[p.player]),
        }),
        players
      ) ?? players,
    [playerData]
  );
  return Object.keys(d).reduce(
    (acc, p) => ({ ...acc, [p]: Object.values(d[p as Player]) }),
    {} as Record<Player, HoleDistribution[]>
  );
};
export default useParDistribution;
