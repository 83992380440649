import { useState } from "react";

function getStorageValue<T>(key: string, defaultValue: T) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved!);
  return (initial || defaultValue) as T;
}

export function useLocalStorage<T>(key: string, defaultValue: T) {
  const [value, _setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  return [
    value,
    (v: T) => {
      localStorage.setItem(key, JSON.stringify(v));
      _setValue(v);
    },
  ] as [typeof value, typeof _setValue];
}
