import { useManagedSelector } from "@debaanop/ui-lib";
import { usePanel, useActivePanel } from "../../hooks";
import HoleSheet from "./HoleSheet";

import { players } from "../../helpers";
import { holeMapper } from "../../components/ScoreCard/Score";

import usePlayerDistribution from "../../services/distribution/usePlayerDistribution";
import useHoleData from "../../services/distribution/useHoleData";
import { useAllCourses } from "../../queries";

type HoleContainerProps = { id?: string; course?: string };

const covers: Record<string, string> = {
  Ranst: "/covers/ranst.jpg",
  Edegem: "/covers/edegem.jpg",
};

const HoleContainer = ({ id }: HoleContainerProps) => {
  const { course } = useActivePanel("hole");
  const openPanel = usePanel();

  // fetch holesInfo for selected course
  const { data: courses } = useAllCourses();
  const holes = courses?.find((c) => c.id === course)?.holes;

  const options =
    holes?.reduce(
      (memo, hole, index) => ({ ...memo, [`${index}`]: hole }),
      {}
    ) ?? {};

  const selectorProps = useManagedSelector({
    options:
      holes?.reduce(
        (memo, { name }, index) => ({ ...memo, [index]: name }),
        {}
      ) ?? {},
    selected: id!,
    onChange: (id: string) => {
      openPanel("hole", { id, course });
    },
    cyclic: true,
  });

  const playerDistributionData = usePlayerDistribution(course as string);
  const holeData = useHoleData(course as string);

  return (
    <HoleSheet
      coverUrl={covers[course as string] ?? "/covers/defaultCourse2.jpg"}
      title={course as string}
      selectorProps={{ ...selectorProps, options }}
      holes={holes ?? []}
      playerDistributionData={playerDistributionData.reduce(
        (acc, d, i) => ({ ...acc, [i.toString()]: Object.values(d) }),
        {} as Record<string, any>
      )}
      onClickPlayerIndex={(id) =>
        openPanel("player", { id: players[id], course })
      }
      scoreCards={Object.keys(holeData).reduce(
        (memo, hole) => ({
          ...memo,
          [hole]: Object.keys(holeData[hole]).map((player) => ({
            _id: player as string,
            title: player as string,
            scores: holeData[hole][player].map(holeMapper),
          })),
        }),
        {}
      )}
    />
  );
};

export default HoleContainer;
