import { CardList, ScrollableContent } from "@debaanop/ui-lib";

import { Course } from "../../types";
import { CourseCard, CourseCardProps } from "../../components/CourseCard";

const covers: Record<string, string> = {
  Ranst: "/covers/ranst.jpg",
  Edegem: "/covers/edegem.jpg",
};

const CourseOverview = ({
  courses,
  onClickCourse,
}: {
  courses: Course[];
  onClickCourse: (courseId: string) => void;
}) => {
  return (
    <ScrollableContent>
      <CardList<CourseCardProps>
        items={courses.map((course) => ({
          _id: course.id,
          name: course.name,
          onClick: () => onClickCourse(course.id),
          coverUrl: covers[course.id] ?? "/covers/defaultCourse2.jpg",
        }))}
      >
        {CourseCard}
      </CardList>
    </ScrollableContent>
  );
};

export default CourseOverview;
