import { BoxProps, Box } from "@mui/material";

import { motion, AnimatePresence } from "framer-motion";

export type PhotoBackgroundProps = {
  src: string;
  masked?: boolean;
} & BoxProps;

const PhotoBackground = ({
  src,
  masked,
  key,
  children,
  ...boxProps
}: PhotoBackgroundProps) => (
  <Box
    {...boxProps}
    sx={{
      ...boxProps.sx,
      overflow: "hidden",
      width: "100%",
      height: "100%",
      position: "relative",
    }}
  >
    <AnimatePresence>
      <motion.div
        transition={{ ease: "easeOut", duration: 0.3 }}
        key={key || src}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            backgroundImage: `url(${src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            ...(masked && {
              maskImage: `linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0.6))`,
              WebkitMaskImage: `linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0.6))`,
            }),
          }}
        />
      </motion.div>
    </AnimatePresence>
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {children}
    </Box>
  </Box>
);

export default PhotoBackground;
