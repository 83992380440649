import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlightApi } from "../services/flight/FlightProvider";
import { FlightData, Player } from "../types";
import { COURSE_FLIGHT } from "../queries/flights";
import { useApi } from "../services/api/DataProvider";

export const useFlightCreate = (course: string) => {
  const queryClient = useQueryClient();
  const { createFlight } = useFlightApi();
  return useMutation({
    mutationFn: (handicaps: Record<Player, number>) =>
      createFlight(course, handicaps),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COURSE_FLIGHT, { course }],
      });
    },
  });
};

export const useFlightUpdate = (course: string) => {
  const queryClient = useQueryClient();
  const { updateFlight } = useFlightApi();
  return useMutation({
    mutationFn: (handicaps: Record<Player, number>) =>
      updateFlight(course, handicaps),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COURSE_FLIGHT, { course }] });
    },
  });
};

export const useHoleStrokesUpdate = (course: string) => {
  const queryClient = useQueryClient();
  const { updateHoleStrokes } = useFlightApi();
  return useMutation({
    mutationFn: ({
      holeIndex,
      strokes,
    }: {
      holeIndex: number;
      strokes: Record<Player, number>;
    }) => updateHoleStrokes(course, holeIndex, strokes),
    onMutate: async ({ holeIndex, strokes }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [COURSE_FLIGHT, { course }],
      });

      // Snapshot the previous value
      const previousFlights = queryClient.getQueryData([
        COURSE_FLIGHT,
        { course },
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData<FlightData[]>(
        [COURSE_FLIGHT, { course }],
        (old) =>
          old!.map((flight) => ({
            ...flight,
            strokes: {
              ...flight.strokes,
              [holeIndex]: strokes[flight.player],
            },
          }))
      );

      // Return a context with the previous and new todo
      return { previousFlights, updates: { holeIndex, strokes } };
    },
    onError: (err, updates, context) => {
      queryClient.setQueryData(
        [COURSE_FLIGHT, { course }],
        context!.previousFlights
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [COURSE_FLIGHT, { course }] });
    },
  });
};

export const useFlightSubmit = (course: string) => {
  const queryClient = useQueryClient();
  const { submitFlight } = useApi();
  return useMutation({
    mutationFn: (players: FlightData[]) => submitFlight(course, players),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [COURSE_FLIGHT, { course }] });
    },
  });
};
